import { Button, Dialog, DialogContent, DialogContentText, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate as useHistory, useLocation } from "react-router-dom";
import Header from "../Shared/Header";
import axios from '../Shared/axios';
import requests from '../Shared/requests';
import Popup from "../Shared/PopUp"
import Dailog from "../Shared/Dailog";
import "../Signin.scss";
import Channel from "./Channel";
const heading = "BROADCAST CHANNEL";

const BroadcastChannel = (props) => {
  const { classes } = props;
  const location = useLocation();
  const history = useHistory();
  const [saveButtonToogle, setSaveButtonToggle] = useState(true);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("")
  const channels = useRef(
    location?.state?.configuredData?.channels?.map((chhanel, index) => {
      return {
        ...chhanel,
        index,
      }
    }));

  useEffect(() => {
    setTimeout(() => {
      if (channels?.current?.every(ch => ch.channelEnable == false)) setSaveButtonToggle(false)
    }, 100)
    return () => setSaveButtonToggle(true);
  }, [])

  const handleChannelUpdate = (value) => {
    channels.current[value.index] = value;
  }

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };
  const handleEditConfig = async (e) => {
    e.preventDefault();
    setSaveButtonToggle(false);
  };

  const menuList = [
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const validateChannel = () => {
    let valid = true;
    channels.current.map(channel => {
      if (channel.channelEnable && (!channel.socialMediaRtmpUrl || !channel.channelName)) {
        setErrorPopUp(true);
        setPopUpMessage("Please enter RTMP URL and channel before enabling a channel")
        valid = false;
      }
    })
    return valid
  }
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (!validateChannel()) {
      return;
    }
    if (channels.current.some((channel) => channel.channelEnable && channel.socialMediaRtmpUrl && channel.channelName)) {
      try {
        const data = {
          channelDTOList: channels.current.map(chnl => {
            delete chnl['index']
            return { ...chnl }
          }),
          venueId: location?.state?.detail?.venueId
        }
        const request = await axios.put(requests.configureSocialMediaRtmpUrl, data);
        setSaveButtonToggle(true);
        setTimeout(() => {
          history(-1);
        }, 500);
      } catch (error) {
        if (error.response.status === 403) {
          handleLogout();
        }
        console.log(error);
      }
    } else {
      setHandlePopUp(true);
    }
  };

  return (
    <>
      <div className="signin-body">
        {errorPopUp ? (
          <Dailog
            handleCancel={() => setErrorPopUp(false)}
            errorDailog={popUpMessage}
          />
        ) : (
          " "
        )}
        {handlePopUp ? (
          <Dialog
            // sx={{ display: 'flex', justifyContent: 'center', maxWidth: '20rem' }}
            maxWidth={'10rem'}
            open={handlePopUp}
            onClose={handleClosePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent >
              <DialogContentText id="alert-dialog-description" textAlign={"center"} sx={{ maxWidth: "18rem", fontWeight: 600 }}>
                Configure and enable at least one channel (RTMP URL + ON)
              </DialogContentText>
              <Button
                className="edit-color"
                fullWidth
                variant="contained"
                onClick={handleClosePopup}
                sx={{ mt: 4, mb: 3 }}
              >
                OK
              </Button>
            </DialogContent>
          </Dialog>
        ) : (
          " "
        )}
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              <Box
                style={classes.boxWidth}
                excel
                noValidate
                fullWidth
                sx={{ mt: 1 }}
              >
                <div className="customer-detail" style={classes.boxWidth}>
                  <Typography className="detail">
                    Venue ID ({location?.state?.detail?.venueId})
                  </Typography>
                  <Typography className="detail">
                    {location?.state?.detail?.venueName}
                  </Typography>
                </div>
                <Typography align="center" sx={{ py: 2, px: 4, fontWeight: 600, fontSize: ".8rem" }}>
                  Stream your games on one or two channels simultaneously
                </Typography>
                <Box
                  component="form"
                  onSubmit={(e) => handleSubmitForm(e)}
                >
                  {
                    channels?.current?.map((chanel, index) => <Channel value={chanel} chanelNumber={index + 1} key={index + 1} updateChannel={handleChannelUpdate} saveButtonToogle={saveButtonToogle} />)
                  }
                </Box>
                {saveButtonToogle ? (
                  <Button
                    className="edit-color"
                    fullWidth
                    variant="contained"
                    onClick={(e) => handleEditConfig(e)}
                    sx={{ mt: 4, mb: 3 }}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    className="sign-in-button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 4, mb: 3 }}
                    type="submit"
                    onClick={handleSubmitForm}
                  >
                    Save
                  </Button>
                )}
                <Typography
                  variant="body2"
                  align="center"
                  // className={`${classes.underLine} ${classes.topMargin}`}
                  style={{
                    fontSize: 13,
                    marginTop: 10,
                    textDecoration: "underline",
                  }}
                >
                  <a className="anchor" target="blank" href="https://www.gamecast.info/support">
                    RTMP Instructions
                  </a>
                </Typography>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BroadcastChannel;
