import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Typography } from "@mui/material";
import { useNavigate as useHistory } from "react-router-dom";
import "../Signin.scss";

export default function AlertDialogSlide(props) {
  const {
    title,
    deleteVenue,
    resetPassword,
    userCreated,
    editVenue,
    deleteVenues,
    newVenueCreated,
    handleCancel,
    deleteCustomer,
    handleDeleteCustomer,
    regGenerateRtmp,
    handleReset,
    limitMessage,
    handleCancelMessage,
    goLive,
    handleConfig,
    deleteEvent,
    deleteEvents,
    errorDailog,
    errorTime,
    manualStopLive,
    preventManualStop,
  } = props;
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (
      deleteVenue ||
      userCreated ||
      newVenueCreated ||
      deleteCustomer ||
      limitMessage ||
      goLive ||
      deleteEvent ||
      errorDailog ||
      errorTime ||
      preventManualStop
    ) {
      handleClickOpen();
    } else {
      handleClose();
    }
  }, [
    deleteVenue,
    userCreated,
    newVenueCreated,
    deleteCustomer,
    limitMessage,
    goLive,
    deleteEvent,
    errorDailog,
    preventManualStop,
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (handleCancel) handleCancel();
  };
  const handleDeleteVenue = () => {
    deleteVenues();
    handleClose();
  };
  const handleUserCreated = () => {
    history("/admin/customerlist");
  };
  const handleVenueCreated = () => {
    history(-1);
  };

  return (
    <div>
      {resetPassword && (
        <Button
          className="sign-in-button margin-negative"
          fullWidth
          variant="contained"
          sx={{ mb: 1 }}
          onClick={handleClickOpen}
        >
          {title}
        </Button>
      )}
      {editVenue && (
        <Button
          className="sign-in-button margin-negative"
          fullWidth
          variant="contained"
          sx={{ mb: 1 }}
          onClick={handleClickOpen}
        >
          {title}
        </Button>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div className="dailog-box">
            <div className="caution-img-box">
              {userCreated && <i className="fas fa-check caution-image"></i>}
              {newVenueCreated && (
                <i className="fas fa-check caution-image"></i>
              )}
              {resetPassword && (
                <i className="fas fa-exclamation-triangle caution-image "></i>
              )}
              {deleteVenue && (
                <i className="fas fa-exclamation-triangle caution-image "></i>
              )}
              {editVenue && (
                <i className="fas fa-exclamation-triangle caution-image "></i>
              )}
              {deleteCustomer && (
                <i className="fas fa-exclamation-triangle caution-image "></i>
              )}
              {limitMessage && (
                <i className="fas fa-exclamation-triangle caution-image "></i>
              )}
              {goLive && (
                <i className="fas fa-exclamation-triangle caution-image "></i>
              )}
              {deleteEvent && (
                <i className="fas fa-exclamation-triangle caution-image "></i>
              )}
              {errorDailog && (
                <i className="fas fa-exclamation-triangle caution-image "></i>
              )}
              {preventManualStop && (
                <i className="fas fa-exclamation-triangle caution-image "></i>
              )}
            </div>
            <div className="dailog-content-box">
              {resetPassword && (
                <>
                  <Typography variant="h1">Reset Password</Typography>
                  <Typography>This action can't be undone</Typography>
                </>
              )}
              {deleteVenue && (
                <>
                  <Typography variant="h1">DELETE VENUE</Typography>
                  <Typography>This action can't be undone</Typography>
                </>
              )}
              {editVenue && (
                <>
                  <Typography variant="h1">RE-GENERATE RTMP LINK?</Typography>
                  <Typography>Camera has to be updated</Typography>
                </>
              )}
              {userCreated && (
                <>
                  <Typography variant="h1">CUSTOMER CREATED</Typography>
                  <Typography>Customer is created succesfully</Typography>
                </>
              )}
              {newVenueCreated && (
                <>
                  <Typography variant="h1">VENUE CREATED</Typography>
                  <Typography>Venue is created succesfully</Typography>
                </>
              )}
              {deleteCustomer && (
                <>
                  <Typography variant="h1">DELETE CUSTOMER</Typography>
                  <Typography>This action can't be undone</Typography>
                </>
              )}
              {limitMessage && (
                <>
                  <Typography variant="h1">VENUE ERROR MESSAGE</Typography>
                  <Typography>
                    To add more venues, <br /> please upgrade your subscription
                  </Typography>
                </>
              )}
              {goLive && (
                <>
                  <Typography variant="h1">
                    Configure Broadcast Channel
                    <br />
                    prior to Live Streaming
                  </Typography>
                </>
              )}
              {deleteEvent && (
                <>
                  <Typography variant="h1">DELETE EVENT</Typography>
                  <Typography>This action cant't be undone</Typography>
                </>
              )}
              {errorDailog && (
                <>
                  <Typography variant="h1" align="center">{errorDailog.toUpperCase()}</Typography>
                  {/* <Typography>{errorDailog}</Typography> */}
                </>
              )}
              {preventManualStop && (
                <>
                  <Typography variant="h1">SCHEDULED GAME IN PROGRESS</Typography>
                  <Typography>Confirm Cancellation of Livestream.</Typography>
                </>
              )}
              {errorTime && (
                <>
                  <Typography variant="h1">DAILY SCHEDULE ALERT</Typography>
                  <Typography>{errorTime}</Typography>
                </>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {deleteVenue && (
            <>
              <Button
                variant="contained"
                className="dailog-button"
                fullWidth
                onClick={() => {
                  handleClose()
                  handleCancel()
                }}
              >
                CANCEL
              </Button>

              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  handleDeleteVenue()
                  handleCancel();
                }}
              >
                DELETE
              </Button>
            </>
          )}

          {editVenue && (
            <>
              <Button
                variant="contained"
                className="dailog-button"
                fullWidth
                onClick={handleClose}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  regGenerateRtmp()
                  handleClose();
                }}
              >
                RE-GENERATE
              </Button>
            </>
          )}
          {resetPassword && (
            <>
              <Button
                variant="contained"
                className="dailog-button"
                fullWidth
                onClick={handleClose}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  handleReset()
                  handleClose();
                }}
              >
                RESET
              </Button>
            </>
          )}
          {userCreated && (
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={handleUserCreated}
            >
              OK
            </Button>
          )}
          {newVenueCreated && (
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={handleVenueCreated}
            >
              OK
            </Button>
          )}
          {deleteCustomer && (
            <>
              <Button
                variant="contained"
                className="dailog-button"
                fullWidth
                onClick={() => {
                  handleClose()
                  handleCancel();
                }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  handleDeleteCustomer()
                  handleClose()
                  handleCancel();
                }}
              >
                DELETE
              </Button>
            </>
          )}
          {limitMessage && (
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => {
                handleCancelMessage()
                handleClose();
              }}
            >
              OK
            </Button>
          )}
          {goLive && (
            <>
              <Button
                variant="contained"
                className="dailog-button"
                fullWidth
                onClick={() => {
                  handleClose()
                  handleCancel();
                }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  handleConfig()
                  handleClose()
                  handleCancel();
                }}
              >
                CONFIGURE
              </Button>
            </>
          )}
          {deleteEvent && (
            <>
              <Button
                variant="contained"
                className="dailog-button"
                fullWidth
                onClick={() => {
                  handleClose()
                  handleCancel();
                }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  deleteEvents()
                  handleClose()
                  handleCancel();
                }}
              >
                DELETE
              </Button>
            </>
          )}
          {(errorDailog || errorTime) && (
            <>
              <Button
                variant="contained"
                //className="dailog-button"
                color="error"
                fullWidth
                onClick={() => {
                  handleClose()
                  handleCancel();
                }}
              >
                OK
              </Button>
            </>
          )}
          {preventManualStop && (
            <>
              <Button
                variant="contained"
                className="dailog-button"
                fullWidth
                onClick={() => {
                  handleClose()
                  handleCancel();
                }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  handleClose()
                  manualStopLive()
                  handleCancel();
                }}
              >
                STOP
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
