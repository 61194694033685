import React, { useEffect, useState, useRef } from "react";
import "../Signin.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logo from "../../assets/logo.jpg";
import { useNavigate as useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import * as CryptoJS from "crypto-js";
import { getUserDetails } from "../Shared/data";
import { useDispatch, useSelector } from "react-redux";
import requests from "../Shared/requests";
import jwt_decode from "jwt-decode";
import axios, { createApiInstance } from "../Shared/axios";
import { loginActions } from "../../redux/actions";
import Footer from "../Shared/Footer";

let passPhrase = "passwordKey";
let keySize = 128 / 32;
let iterationCount = 1000;
let iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
let salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

export default function SignIn(props) {
  const { classes } = props;
  let token;
  let decoded;
  const dispatch = useDispatch();
  const [signInError, setSignInError] = useState(false);
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [signInValues, setSignInValues] = useState({
    userId: "",
    password: "",
  });
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      history("/admin/dashboard");
    }
    if (localStorage.getItem("credential")) {
      const data = JSON.parse(localStorage.getItem("credential"));
      setSignInValues({
        userId: data.userId,
        password: data.password,
      });
    }
    return () => {
      return false;
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignInValues((preValue) => {
      return { ...preValue, [name]: value };
    });
  };

  const handleChecked = (e) => {
    setChecked(!checked);
  };

  const login = async () => {
    if (localStorage.getItem("access_token")) {
      history("/admin/dashboard");
      const data = {
        isLoggedIn: true,
        token: localStorage.getItem("access_token"),
        ...getUserDetails(),
      };
    }
  };

  const fetchAccessTocken = async (credentials) => {
    try {
      // let ciphertext = encrypt(salt, iv, passPhrase, credentials.password);
      // let aesPassword = iv + "::" + salt + "::" + ciphertext;
      // let password = btoa(aesPassword);
      const { data } = await axios.post(requests.login, {
        // params: {
        userId: credentials.userId,
        password: credentials.password,
        role: 'admin'
        // loginPortal:"ADMIN"
        // },
      });
      // const {data} = await createApiInstance().post('gamecast/user/login',{
      //   userId: 10001,
      //       password: 'string'
      //     })
      // const sessioTime = {
      //   tokenExpire: data.valid,
      //   timeStamp: new Date()
      // }
      localStorage.setItem("access_token", data.data.token);
      token = data.data.token;
      decoded = jwt_decode(token);
      dispatch(loginActions.setLoggedIn(decoded));
      login();
    } catch (error) {
      setSignInError("Invalid User ID or password. Please try again");
      console.log(error);
    }
  };

  const generateKey = (salt, passPhrase) => {
    let key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
      keySize: keySize,
      iterations: iterationCount,
    });
    return key;
  };

  const encrypt = (salt, iv, passPhrase, plainText) => {
    let key = generateKey(salt, passPhrase);
    let encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let reg = new RegExp("^[0-9]+$");

    if (data.get("userId") && data.get("password")) {
      if (reg.test(data.get("userId"))) {
        const credentials = {
          userId: data.get("userId"),
          password: data.get("password"),
        };
        if (checked) {
          localStorage.setItem("credential", JSON.stringify(credentials));
        }
        fetchAccessTocken(credentials);
      } else {
        setSignInError("only numeric values are allowed");
      }
    } else {
      setSignInError("Invalid User ID or password. Please try again");
    }
  };

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img className="gamecast-logo" src={logo} alt="Game Cast logo" />
            <Typography
              style={{ fontWeight: "bold", marginTop: 23, fontSize: 19 }}
            >
              GAMECAST ADMIN
            </Typography>
            <Typography variant="body2" color="error" className="alert-message">
              {signInError ? signInError : " "}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, padding: "0 6px" }}
              style={classes.boxWidth}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="userId"
                label="User ID"
                name="userId"
                autoFocus
                size="small"
                style={classes.input}
                value={signInValues.userId}
                color="error"
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                size="small"
                value={signInValues.password}
                style={classes.input}
                color="error"
                onChange={handleChange}
              />
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="checkbox"
                  id="checkbox_id"
                  value="value"
                  onClick={handleChecked}
                />
                <label htmlFor="checkbox_id">Remember my User ID</label>
              </div>
              <Button
                className="sign-in-button"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2, pt: 1.5, pb: 1.5 }}
              >
                Sign On
              </Button>
            </Box>
          </Box>
          <Footer />
        </div>
      </Container>
    </div>
  );
}
