import React, { useState, useEffect } from "react";
import "../Signin.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import Header from "../Shared/Header";
import Menu from "../Shared/Menu";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import { useLocation } from "react-router-dom";
import Dailog from "../Shared/Dailog";
import Popup from "../Shared/PopUp";
import CONSTANTS from "../../constants";
import { getQueryString } from "../Shared/data";
import ReactPaginate from "react-paginate";
const heading = "BROADCASTS";
let pageList;

const Broadcasts = (props) => {

  const location = useLocation();
  const { classes } = props;
  const [broadcastList, setBroadcastList] = useState([]);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updatedList, setUpdatedList] = useState([]);
  const [deleteEventData, setDeleteEventData] = useState("");
  const history = useHistory();
  const [pageCount, setpageCount] = useState(0);
  let limit = 10;

  useEffect(() => {
    getUsersEvents();
  }, []);

  useEffect(() => {
    let list = broadcastList;
    if (updatedList) {
      setpageCount(Math.ceil(updatedList.length / limit));
      setBroadcastList(list.splice(0, 10));
    }
  }, [updatedList]);
  useEffect(() => {
    let list = updatedList;
    let index = pageNumber * 10;
    if (pageNumber > 0) {
      if (list.length > 10) {
        pageList = list.slice(index, index + 10);
      }
    } else if (pageNumber === 0) {
      pageList = list.slice(index, 10);
    }
    setBroadcastList(pageList);
  }, [pageNumber]);

  const getUsersEvents = async () => {
    try {
      const request = await axios.get(
        requests.getAllSheduleEvents + `/${location?.state?.detail?.venueId}`
      );
      convertTimeZone(request.data.data);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      } else {
        setHandlePopUp(true);
        if (error.response.statusText) {
          setPopUpMessage(error.response.statusText);
        } else {
          setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
        }
        console.log(error.response);
      }
    }
  };

  const convertTimeZone = (sheduleEvents) => {
    setBroadcastList(sheduleEvents);
    setUpdatedList(sheduleEvents);
  };

  const handlePageClick = async (data) => {
    setPageNumber(data.selected);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };

  const handleDeleteBroadcast = (e, d) => {
    setDeleteEventData(d);
    setDeleteEvent(true);
  };
  const handleCancel = () => {
    setDeleteEvent(false);
  };
  const deleteEvents = async () => {
    try {
      const data = {
        eventId: deleteEventData.eventId,
        venueId: location?.state?.detail?.venueId,
      };
      const request = await axios.delete(
        requests.deleteBroadcastEvent + "?" + getQueryString(data)
      );
      getUsersEvents();
      setSuccessMessage("Broadcast is deleted successfully")
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      console.log(error);
    }
  };
  const handleEditBroadcast = (e, data) => {
    history("/editBroadcast", {
      state: { detail: location?.state?.detail, broadcastDetail: data },
    });
  };

  const menuList = [
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const venueEditList = [
    {
      menuItem: "Edit",
      handle: handleEditBroadcast,
    },
    {
      menuItem: "Delete",
      handle: handleDeleteBroadcast,
    },
  ];
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };
  const handleCloseSuccess = () => {
    setSuccessMessage("")
  }

  const handleNewBroadcast = () => {
    history("/newBroadcast", {
      state: { detail: location?.state?.detail, events: broadcastList },
    });
  };
  const monthName = {
    monthMay: "May",
    monthJun: "Jun",
    monthJul: "Jul",
    monthSep: "Sep"
  }
  const addDot = (arr) => {
    let newArr = arr.split("-");
    if (arr.split("-")[0] == monthName.monthMay) {
      newArr[0] = `May`;
    } else if (arr.split("-")[0] == monthName.monthJun) {
      newArr[0] = `June`;
    } else if (arr.split("-")[0] == monthName.monthJul) {
      newArr[0] = `July`;
    } else if (arr.split("-")[0] == monthName.monthSep) {
      newArr[0] = `Sept.`;
    } else {
      newArr[0] = `${arr.split("-")[0]}.`;
    }
    return newArr.join("-");
  };

  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth={true}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header
                menuList={menuList}
                heading={heading}
                location={location}
              />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              {successMessage ? (
                <Dailog
                  handleCancel={handleCloseSuccess}
                  errorDailog={successMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                // className={classes.boxWidth}
                style={classes.boxWidth}
                noValidate
                fullWidth={true}
                sx={{ mt: 1 }}
              >
                {deleteEvent ? (
                  <Dailog
                    handleCancel={handleCancel}
                    deleteEvent={deleteEvent}
                    deleteEvents={deleteEvents}
                  />
                ) : (
                  " "
                )}
                <div className="customer-detail" style={classes.boxWidth}>
                  <Typography className="detail">
                    {location?.state?.detail?.venueId}
                  </Typography>
                  <Typography className="detail">
                    {location?.state?.detail?.venueName}
                  </Typography>
                  <Typography className="detail">
                    {location?.state?.detail?.timeZone}
                  </Typography>
                </div>
                <Button
                  className="sign-in-button"
                  fullWidth={true}
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  onClick={handleNewBroadcast}
                >
                  New Broadcast
                </Button>
                <div className="broadcasts-container">
                  {broadcastList.map((data, index) => {
                    return (
                      <div className="broadcast-box" key={index}>
                        <Typography>{data.eventName}</Typography>|
                        <div>
                          <Typography>{addDot(data?.date)}</Typography>
                          <Typography>
                            {data.startTime}{" "}
                            <i className="fas fa-long-arrow-alt-right"></i>{" "}
                            {data.endTime}
                          </Typography>
                        </div>
                        <Menu
                          broadcastOptions={true}
                          menuList={venueEditList}
                          userData={data}
                        />
                      </div>
                    );
                  })}
                </div>
                <Container>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination-list"}
                    pageClassName={"page-items"}
                    pageLinkClassName={"page-links"}
                    previousClassName={"page-items"}
                    previousLinkClassName={"page-links"}
                    nextClassName={"page-items"}
                    nextLinkClassName={"page-links"}
                    breakClassName={"page-items"}
                    breakLinkClassName={"page-links"}
                    activeClassName={"active-item"}
                  />
                </Container>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Broadcasts;
