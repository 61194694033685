const VARIABLES = {
  CREDENTIAL_COPY: 'Credentials copied successfully',
  USER_DELETE_SUCCESS: 'CUSTOMER IS SUCCESSFULLY DELETED',
  VENUE_EDITED_SUCCESS: 'Venue is edited Successfully',
  VENUE_DELETED_SUCCESS: 'Venue is deleted Successfully',
  VENUE_CREATED: 'Venue us created Successfully',
  RTMP_REGENERATED: 'Rtmp URL Re-generated Successfully',
  USER_EDITED_SUCCESS: 'Customer is Edited Successfully',
  EVENT_CREATED_SUCCESS: "Event is Created Successfully",
  BROADCAST_NAME: "Broadcast name is mandatory",
  RTMP_REQUIRED: "Destination RTMP address can't be blank",
  NETWORK_ERROR: "Network Error",
  EVENT_DELETE_SUCCESS: "Event is deleted successfully",
  EVENT_EDITED_SUCCESS: "Event is Edited successfully",
  PREVIOUS_TIME_ERROR: "You cannot select previous time",
  END_TIME_GREATER: "End time should be greater then starting time"
};

export default VARIABLES;
