import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const Channel = ({ value, chanelNumber, updateChannel, saveButtonToogle }) => {
    const [customSocial, setCustomSocial] = useState(false);
    const [channelValue, setChannelValue] = useState(value)
    const [activeStatus, setActiveStatus] = useState(false);
    const socialArr = ['Youtube', 'Twitch', 'Facebook']

    useEffect(() => {
        setActiveStatus(value.channelEnable)
        if (!socialArr.includes(value.channelName)) {
            setCustomSocial(true);
        }
    }, [])

    useEffect(() => {
        if (channelValue.channelName && !socialArr.includes(channelValue.channelName)) {
            setCustomSocial(true);
        } else {
            setCustomSocial(false);
        }
    }, [channelValue])

    useEffect(() => {
        updateChannel({ ...channelValue, channelEnable: activeStatus })
    }, [channelValue, activeStatus])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setChannelValue((prevValue) => {
            return { ...prevValue, [name]: value };
        });
    };

    return (
        <Box
            sx={{ border: 1, borderColor: '#b7b7b7' }}
        >
            <Typography align="center" variant="h6" sx={{ fontWeight: 900, pt: 2 }} >CHANNEL {chanelNumber}</Typography>
            <Box
                className="activ-inactive" sx={{ p: 2 }}
            >
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={activeStatus}
                        name="active"
                        id="active"
                        onChange={() => setActiveStatus(!activeStatus)}
                        disabled={saveButtonToogle}
                    />
                    <span className="slider round"></span>
                </label>
                <Typography variant="body2">
                    {activeStatus ? "on" : "off"}
                </Typography>
            </Box>
            <div className="timeZoneBox">
                <i className="fas fa-caret-down select-icon"></i>
                <select
                    name="channelName"
                    value={
                        socialArr.includes(channelValue?.channelName) ?
                            channelValue?.channelName : channelValue?.channelName?.length ?
                                'Custom' : ''
                    }
                    id="social"
                    onChange={(e) => handleChange(e)}
                    disabled={saveButtonToogle}
                >
                    <option value="">Select Channel</option>
                    <option value="Twitch">Twitch</option>
                    <option value="Facebook">Gamechanger</option>
                    <option value="Youtube">Youtube</option>
                    <option value="Custom">Custom</option>
                </select>
            </div>
            {customSocial ? (
                <TextField
                    margin="none"
                    required
                    id="customSocial"
                    label="Enter Custom Name"
                    name="channelName"
                    fullWidth
                    size="small"
                    color="error"
                    value={channelValue.channelName}
                    onChange={(e) => handleChange(e)}
                    sx={{ mt: 0, mb: 2 }}
                    // style={{marginLeft:-10}}
                    disabled={saveButtonToogle}
                />
            ) : (
                ""
            )}

            <TextField
                margin="none"
                required
                id="rtmpAddress"
                label="Enter destination RTMP address"
                name="socialMediaRtmpUrl"
                fullWidth
                size="small"
                color="error"
                value={channelValue.socialMediaRtmpUrl}
                onChange={(e) => handleChange(e)}
                sx={{ mt: 0, mb: 0 }}
                // style={{marginLeft:-10}}
                disabled={saveButtonToogle}
            />
        </Box>
    )
}

export default Channel