import { useEffect, useRef, useState } from 'react'

const CountdownTimer = ({ initialTime, live, manualStop, prevTime, manualLiveStarted, liveTypeManual }) => {
    const [timeLeft, setTimeLeft] = useState(null);
    const intervalId = useRef();

    const resetTime = () => {
        if (initialTime) {
            let [hours, minutes, seconds] = initialTime?.split(':').map(Number);
            const [prevHours, prevMinutes, prevSeconds] = prevTime?.split(':').map(Number) || [0, 0, 0];
            const totalSeconds = hours * 3600 + minutes * 60 + seconds;
            const totalPrevSeconds = prevHours * 3600 + prevMinutes * 60 + prevSeconds;
            setTimeLeft(totalSeconds - totalPrevSeconds);
        }
    }

    useEffect(() => {
        if (initialTime) {
            resetTime();
        }
    }, [initialTime, liveTypeManual]);

    useEffect(() => {
        if (timeLeft == 0) {
            manualStop()
        }
    }, [timeLeft])

    useEffect(() => {
        intervalId.current = setInterval(() => {
            if (live && liveTypeManual) {
                setTimeLeft((prevTimeLeft) => prevTimeLeft && prevTimeLeft - 1);
            } else {
                resetTime();
                if (intervalId.current) {
                    clearInterval(intervalId.current)
                }
            }
        }, 1000);
        return () => {
            clearInterval(intervalId.current);
        };
    }, [live, manualLiveStarted, liveTypeManual]);

    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return (
        <>
            {liveTypeManual ? `${formattedHours}:${formattedMinutes}` : initialTime?.slice(0, 5)}
        </>
    );
}

export default CountdownTimer


