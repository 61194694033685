import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate as useHistory } from "react-router-dom";
import "../Signin.scss";
// import { makeStyles } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CONSTANTS from "../../constants";
import Dailog from "../Shared/Dailog";
import Header from "../Shared/Header";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
const heading = "NEW CUSTOMER";
const errors = {};
const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
const regex2 = /^[0-9]*$/i;
let formData;
let formEvent;
let credential;

const Newcustomer = (props) => {
  const { classes } = props;
  const [preGeneratedUserId, setPreGeneratedUserId] = useState("");
  const [preGeneratedPassword, setPreGeneratedPassword] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState();
  const [updatedValue, setUpdatedValue] = useState({
    password: preGeneratedPassword,
    customerName: "",
    customerFirstName: "",
    customerLastName: "",
    customerEmail: "",
    customerNumber: "",
    subscription: "1",
  });
  const history = useHistory();


  useEffect(() => {
    getUserId();
  }, []);


  useEffect(() => {
    if (preGeneratedPassword) {
      setUpdatedValue((preValue) => {
        return { ...preValue, password: preGeneratedPassword };
      });
      credential = JSON.stringify({
        userId: preGeneratedUserId,
        password: updatedValue.password,
      });
    }
  }, [preGeneratedPassword]);


  const getUserId = async () => {
    try {
      const { data } = await axios.get(requests.getUserIdAndPassword);
      setPreGeneratedUserId(data?.data.userId);
      setPreGeneratedPassword(data?.data.password);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      } else {
        setHandlePopUp(true);
        if (error.response.data.message) {
          setPopUpMessage(error.response.data.message);
        } else {
          setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedValue((preValue) => {
      return { ...preValue, [name]: value };
    });
  };
  // useEffect(() => {

  // }, [updatedValue]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    formEvent = event;
    setIsSubmit(true);
    const data = new FormData(event.currentTarget);
    data.set("customerId", preGeneratedUserId);
    // data.set("password", preGeneratedPassword);
    formData = {
      email: data.get("customerEmail"),
      firstName: data.get("customerFirstName"),
      lastName: data.get("customerLastName"),
      organizationName: data.get("customerName"),
      password: updatedValue.password,
      phone: data.get("customerNumber"),
      userId: data.get("customerId"),
      venueSubscription: data.get("subscription"),
    };
    validate(updatedValue);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      createUser()
    } else {
      setIsSubmit(false);
    }
  }, [Object.keys(errors) && isSubmit]);

  const validate = (values) => {
    if (!values.customerName) {
      errors.customerName = "Customer Name is required";
    } else {
      delete errors.customerName;
    }
    if (!values.customerFirstName) {
      errors.customerFirstName = "First Name is required!";
    } else {
      delete errors.customerFirstName
    }
    if (!values.customerLastName) {
      errors.customerLastName = "Last Name is required!";
    } else {
      delete errors.customerLastName;
    }
    if (!values.customerEmail) {
      errors.customerEmail = "A valid Email is required";
    } else if (!regex.test(values.customerEmail)) {
      errors.customerEmail = "A valid Email is required";
    } else {
      delete errors.customerEmail;
    }
    if (!values.customerNumber) {
      errors.customerNumber = "Customer contact phone is required!";
    } else if (!regex2.test(values.customerNumber)) {
      errors.customerNumber = "Valid contact phone is required!";
    } else {
      delete errors.customerNumber;
    }
    return errors;
  };


  const createUser = async () => {
    try {
      const request = await axios.post(requests.userHandler, formData);
      setUserCreated(true);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.data.message) {
        setPopUpMessage(error.response.data.message);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
    }
  }

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/admin/");
  };
  const customerListHandler = () => {
    history("/admin/customerlist");
  };
  const newCustomer = () => {
    history("/admin/newcustomer");
  };

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const handleCopy = () => {
    try {
      credential = JSON.stringify({
        userId: preGeneratedUserId,
        password: updatedValue.password,
      });
      navigator.clipboard.writeText(credential);
      setHandlePopUp(true);
      setPopUpMessage(CONSTANTS.VARIABLES.CREDENTIAL_COPY)
    } catch (error) {
      console.log(error);
    }
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };

  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header
                menuList={menuList}
                heading={heading}
                formErrors={errors}
              />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              <Dailog userCreated={userCreated} />
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                onSubmit={handleSubmit}
                sx={{ minHeight: "26rem", height: "auto" }}
              >
                <TextField
                  margin="normal"
                  required
                  id="customerId"
                  label="Customer ID"
                  name="customerId"
                  autoFocus
                  fullWidth
                  value={preGeneratedUserId}
                  disabled={true}
                  style={classes.inputFieldBold}
                  size="small"
                  color="error"
                />
                <TextField
                  margin="normal"
                  required
                  id="password"
                  label="Password"
                  name="password"
                  autoFocus
                  fullWidth
                  size="small"
                  value={updatedValue.password}
                  style={classes.inputFieldBold}
                  onChange={(e) => handleChange(e)}
                  color="error"
                />
                <CopyToClipboard text={credential} onCopy={handleCopy}>
                  <Button
                    className="sign-in-button"
                    fullWidth
                    variant="contained"
                  //onClick={handleCopy}
                  >
                    Copy Credentials
                  </Button>
                </CopyToClipboard>
                <TextField
                  margin="normal"
                  required
                  id="customerName"
                  label="Customer Name"
                  name="customerName"
                  autoFocus
                  fullWidth
                  size="small"
                  color="error"
                  value={updatedValue.customerName}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="customerFirstName"
                  label="Customer Contact First Name"
                  name="customerFirstName"
                  fullWidth
                  size="small"
                  color="error"
                  value={updatedValue.customerFirstName}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="customerLastName"
                  label="Customer Contact Last Name"
                  name="customerLastName"
                  fullWidth
                  size="small"
                  color="error"
                  value={updatedValue.customerLastName}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="customerEmail"
                  label="Customer Contact Email"
                  name="customerEmail"
                  fullWidth
                  size="small"
                  color="error"
                  value={updatedValue.customerEmail}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="customerNumber"
                  label="Customer Contact Phone"
                  name="customerNumber"
                  fullWidth
                  size="small"
                  color="error"
                  value={updatedValue.customerNumber}
                  onChange={(e) => handleChange(e)}
                />
                <div className="timeZoneBox">
                  <i className="fas fa-caret-down select-icon"></i>
                  <select
                    name="subscription"
                    id="subscription"
                    value={updatedValue.subscription}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="1">Subscription - 1 Venue</option>
                    <option value="2">2 Venues</option>
                    <option value="3">3 Venues</option>
                  </select>
                </div>
                <Button
                  type="submit"
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Newcustomer;
