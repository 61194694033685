import React, { useState, useEffect } from "react";
import "../Signin.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
// import { Typography } from "@mui/material";
import Header from "../Shared/Header";
import Menu from "../Shared/Menu";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import { useLocation } from "react-router-dom";
import Dailog from "../Shared/Dailog";
import Popup from "../Shared/PopUp";
import CONSTANTS from "../../constants";
import { getQueryString } from "../Shared/data";
import { Typography } from "@mui/material";

const heading = "CUSTOMER";

const CustomerDetail = (props) => {
  const location = useLocation();
  const { classes } = props;
  const [activeStatus, setActiveStatus] = useState(
    location?.state?.detail?.active
  );
  const [venuesList, setVenuesList] = useState([]);
  const [venueDetail, setVenueDetails] = useState("");
  const [deleteVenue, setDeleteVenue] = useState(false);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [limitMessage, setLimitMessage] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getAllVenuesList();
  }, []);

  useEffect(() => {
    getActivityStatus();
  }, [activeStatus]);

  const getActivityStatus = async () => {
    if (activeStatus) {
      try {
        const data = {
          status: activeStatus,
          userId: location?.state?.detail?.userId,
        };
        const request = await axios.put(
          requests.accoutStatus + "?" + getQueryString(data)
        );
      } catch (error) {
        if (error.response.status === 403) {
          handleLogout();
        }
      }
    } else {
      setTimeout(async () => {
        try {
          const data = {
            status: activeStatus,
            userId: location?.state?.detail?.userId,
          };
          const request = await axios.put(
            requests.accoutStatus + "?" + getQueryString(data)
          );
        } catch (error) {
          console.log(error)
        }
      }, 1000);
    }
    setActiveStatus(activeStatus);
  }
  const getAllVenuesList = async () => {
    try {
      const { data } = await axios.get(
        requests.getAllVenues + "/" + location?.state?.detail?.userId
      );
      setVenuesList(data.data);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error.response);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/admin/");
  };
  const customerListHandler = () => {
    history("/admin/customerlist");
  };
  const newCustomer = () => {
    history("/admin/newcustomer");
  };
  const handleEditCustomer = () => {
    history("/admin/editCustomer", {
      state: { detail: location?.state?.detail },
    });
  };

  const handleDeleteVenue = (e) => {
    e.stopPropagation();
    setDeleteVenue(true);
  };

  const handleCancel = () => {
    setTimeout(() => {
      setDeleteVenue(false);
    }, 2000);
  };

  const deleteVenues = async () => {
    try {
      const data = await axios.delete(
        requests.createVenue + "/" + venueDetail.venueId
      );
      getAllVenuesList();
      setHandlePopUp(true);
      setPopUpMessage(CONSTANTS.VARIABLES.VENUE_DELETED_SUCCESS)
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error);
    }
  };

  const handleEditVenue = (e) => {
    e.stopPropagation();
    history('/admin/editVenue', {
      state: { detail: venueDetail },
    });
  };

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Edit Customer",
      handle: handleEditCustomer,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const venueEditList = [
    {
      menuItem: "Edit",
      handle: handleEditVenue,
    },
    {
      menuItem: "Delete",
      handle: handleDeleteVenue,
    },
  ];
  const handleActive = (e) => {
    setActiveStatus(!activeStatus);
  };
  const handleNewVenue = () => {
    let count = location?.state?.detail?.venueSubscription;
    if (venuesList.length < count) {
      history("/admin/newVenue", {
        state: { detail: location?.state?.detail, venuesList: venuesList },
      });
    } else {
      setLimitMessage(true);
    }
  };

  const handleVenue = (e, venue) => {
    //e.stopPropogation();
    history('/admin/venueDashboard', {
      state: { detail: venue },
    })
  };

  const handleClosePopup = () => {
    setHandlePopUp(false);
  };

  const handleCancelMessage = () => {
    setLimitMessage(false);
  };

  const systemSchedule = () => {
    history('/admin/systemSchedule', { state: location.state })
  }

  const manualStreamLimit = () => {
    history('/admin/manualStream', { state: location.state })

  }

  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                sx={{ width: "100%" }}
              >
                {deleteVenue ? (
                  <Dailog
                    handleCancel={handleCancel}
                    deleteVenue={deleteVenue}
                    deleteVenues={deleteVenues}
                  />
                ) : (
                  " "
                )}
                {limitMessage ? (
                  <Dailog
                    limitMessage={limitMessage}
                    handleCancelMessage={handleCancelMessage}
                  />
                ) : (
                  " "
                )}
                <div className="customer-detail-box" style={{ ...classes.boxWidth, marginTop: '1rem' }}>
                  <Typography className="details">
                    {location?.state?.detail?.organizationName}
                  </Typography>
                  <Typography className="details">
                    {location?.state?.detail?.userId}
                  </Typography>
                </div>
                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2, mt: 2 }}
                  onClick={handleEditCustomer}
                >
                  Edit Customer
                </Button>
                <div className="activ-inactive">
                  <Typography varient="body1">Active</Typography>
                  <label className="switch">
                    <input
                      type="checkbox"
                      defaultChecked={activeStatus}
                      name="active"
                      id="active"
                      onClick={(e) => handleActive(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <Typography variant="body2">
                    {activeStatus ? "on" : "off"}
                  </Typography>
                </div>
                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={systemSchedule}
                >
                  System Schedule
                </Button>
                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={manualStreamLimit}
                >
                  Manual Stream Limit
                </Button>
                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  onClick={handleNewVenue}
                >
                  New Venue
                </Button>
                <div className="venue-box">
                  {venuesList?.map((venue, index) => {
                    return (
                      <div className="venue" key={index} onClick={(e) => handleVenue(e, venue)}>
                        <Typography varient="h2" className="venue-name">
                          {venue.venueName}
                        </Typography>
                        <Menu
                          kababVenue={true}
                          menuList={venueEditList}
                          userData={venue}
                          setVenueDetails={setVenueDetails}
                        />
                      </div>
                    );
                  })}
                </div>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CustomerDetail;
