import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import CustomerApp from "./CustomerApp";
import "./index.scss";
import store from "./redux/store";
//let origin = "https://admin.gamecast.info/";
let origin = window.location.origin;
let href = window.location.href;

const root = ReactDOM.createRoot(document.querySelector("#root"))
root.render(
  <BrowserRouter>
    <Provider store={store}>
      {/* {
        (origin === "https://admin.gamecast.info") ?
          <App />
          :
          <CustomerApp />
      } */}
      {
        href.includes(`${origin}/admin`) ?
          <App />
          :
          <CustomerApp />
      }
    </Provider>
  </BrowserRouter>
);

// module.hot.accept();
