import React, { useState, useEffect, useRef } from "react";
import "./Streams.css";
import logo from "../Poc/logo.png";
import userImage from "../Poc/user-solid.svg";
// import liveDot from "../Components/livedot.svg";
const axios = require("axios");

let userName = "pankaj.raj@oodles.io";
let password = "123456789";
let inputUrl = "";
const VideoStreamsBox = () => {
  const mediaPlayer = useRef(null);
  const [liveId, setLiveId] = useState();
  const [videoSrc, setVideoSrc] = useState();
  const [inputVal, setInputVal] = useState();
  const [goLiveToggle, setGoLiveToggle] = useState();
  const [stopLiveToggle, setStopLiveToggle] = useState();
  const [liveLableToggle, setLiveLableToggle] = useState();
  const [stopLableToggle, setStopLableToggle] = useState();
  
  const saveHandler = (inputVal) => {
    const data = {
      rtmpUrl: inputVal,
    };
    axios
      .post(
        "http://54.219.72.230:5080/LiveApp/rest/v2/broadcasts/659238420814072771752523/rtmp-endpoint",
        data
      )
      .then(function (response) {
        setLiveId(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const LiveStop = () => {
    let dataId = "hello"
    stopLiveStream(liveId.data.dataId);
  };
  const stopLiveStream = (dataId) =>{
    const url = `http://103.46.238.132:8080/media?dataId=${dataId}&streamId=659238420814072771752523`;
    axios
      .post(url)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onChangeHandler = (element) => {
    setInputVal(element.target.value);
  };

  const submitHandler = () => {
    if (inputVal) {
      if (inputVal !== inputUrl) {
        setLiveLableToggle(false);
        setGoLiveToggle(true);
        setVideoSrc(inputVal);
        inputUrl = inputVal;
        setStopLiveToggle(false);
        setStopLableToggle(false);
      } else if (inputVal === inputUrl && !goLiveToggle) {
        alert("Please Enter the New RTMP Destination");
      } else if (inputUrl === inputVal && !!goLiveToggle) {
        setGoLiveToggle(true);
        setVideoSrc(inputVal);
        inputUrl = inputVal;
      }
    } else {
      alert("Please Enter RTMP Destination...");
    }
  };
  const goLiveHandler = () => {
    saveHandler(videoSrc);
    setStopLiveToggle(true);
    setLiveLableToggle(true);
    setGoLiveToggle(false);
    setStopLableToggle(false);
  };
  const stopLiveHandler = () => {
    LiveStop();
    setGoLiveToggle(true);
    setStopLiveToggle(false);
    setStopLableToggle(true);
    setLiveLableToggle(false);
  };
  return (
    <>
      <div className="main-container">
        <div className="heading">
          <span className="logo-box">
            <img src={logo} alt="GameCast Logo" />
          </span>
          <span className="user-info">
            <span className="user-img">
              <img src={userImage} alt="user img" />
            </span>
            <span className="user-name">
              <h5>Twin Cities LL</h5>
            </span>
          </span>
        </div>
        <div className="main-body">
          <div className="video-streams-box">
            <div className="video-box">
              <div className="mystream">
                <span className="videoHeading">
                  <h4>Live Preview from Joe Wagner Field</h4>
                  <hr></hr>
                </span>
                <div className="frame-video">
                  <iframe
                    ref={mediaPlayer}
                    title="liveStream"
                    width="100%"
                    height="100%"
                    src="http://13.57.169.199:5080/10003/play.html?name=180162041176603895949767"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
                {/* <div  className="dumy-text">
                    <p>Live Stream</p>
                </div> */}
              </div>
              {/* <div className="liveBar">
                  <img src={liveDot} alt="Live" />
                  <h5>Live</h5>
              </div> */}
            </div>
          </div>
          <div className="broad-cast">
            <h1>BROADCAST GAMES</h1>
          </div>
          <div className="inputBox">
            <div className="inputBox-outer">
              <input
                type="text"
                placeholder="Enter the RTMP url and Stream Key combination of your broadcast destination."
                value={inputVal}
                onChange={onChangeHandler}
              />
            </div>
            <div className="submit-box">
              <span>
                <h3>Destination RTMP Address</h3>
              </span>
              <button
                type="submit"
                onClick={() => {
                  submitHandler();
                }}
              >
                SAVE
              </button>
            </div>
            <div className="decleration-box">
              <span className="instruction">
                <h3>Instructions:</h3>
              </span>
              <span className="instruction-para">
                <p>
                  Enter the RTMP and Stream Key combination of your broadcast
                  destination of choice below. such as Youtube, Twitch,
                  Facebook, etc. For more information on how to configure this
                  section. please visit our <a href="/">support page</a>
                </p>
              </span>
            </div>
          </div>
          <div className="controlBox">
            <div>
              <span>
                {goLiveToggle ? (
                  <button
                    className="go-live"
                    onClick={(e) => {
                      goLiveHandler(e);
                    }}
                  >
                    Go Live
                  </button>
                ) : (
                  ""
                )}
              </span>
              {liveLableToggle ? (
                <span>
                  <h4 className="live-lable">You are currently broadcasting</h4>
                </span>
              ) : (
                ""
              )}
            </div>
            <div>
              <span>
                {stopLiveToggle ? (
                  <button
                    className="stop-button"
                    onClick={(e) => {
                      stopLiveHandler(e);
                    }}
                  >
                    Stop
                  </button>
                ) : (
                  ""
                )}
              </span>
              {stopLableToggle ? (
                <span>
                  <h4 className="offline-lable">You are currently offline</h4>
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoStreamsBox;