import React, { useState, useEffect } from "react";
import "../Signin.scss";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
// import { makeStyles } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Header from "../Shared/Header";
import requests from "../Shared/requests";
import axios from "../Shared/axios";
import { useLocation } from "react-router-dom";
import Dailog from "../Shared/Dailog";
import Popup from "../Shared/PopUp";
import CONSTANTS from "../../constants";

const heading = "EDIT CUSTOMER";

const EditCustomer = (props) => {
  const { classes } = props;
  const location = useLocation();
  const [preGeneratedUserId, setPreGeneratedUserId] = useState(
    location?.state?.detail?.userId
  );
  const [preGeneratedPassword, setPreGeneratedPassword] = useState("");
  const [handlePopUp, setHandlePopUp] = useState(false);
  const history = useHistory();
  const [popUpMessage, setPopUpMessage] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [updatedValue, setUpdatedValue] = useState({
    password: preGeneratedPassword,
    customerName: location?.state?.detail?.organizationName,
    customerFirstName: location?.state?.detail?.firstName,
    customerLastName: location?.state?.detail?.lastName,
    customerEmail: "",
    customerNumber: "",
    subscription: location?.state?.detail?.venueSubscription,
  });
  useEffect(() => {
    if (preGeneratedPassword) {
      setUpdatedValue((preValue) => {
        return { ...preValue, password: preGeneratedPassword };
      });
    }
    // return ()=>{
    //   // return false
    // }
  }, [preGeneratedPassword]);
  useEffect(() => {
    getData()

    // return ()=>{
    //   // return false
    // }
  }, []);

  const handleCloseSuccess = () => {
    setSuccessMessage("")
    history("/admin/customerlist");
  }

  const getData = async () => {

    try {
      const { data } = await axios.get(
        requests.userHandler + `/${location?.state?.detail?.userId}`
      );
      setUpdatedValue((preValue) => {
        return {
          ...preValue, password: data.data.password,
          customerName: data.data.organizationName,
          customerFirstName: data.data.firstName,
          customerLastName: data.data.lastName,
          customerEmail: data.data.email,
          customerNumber: data.data.phone,
          subscription: data.data.venueSubscription,
        };
      });

    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error);
    }

  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedValue((preValue) => {
      return { ...preValue, [name]: value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.set("customerId", preGeneratedUserId);
    data.set("password", preGeneratedPassword);
    const formData = {
      email: updatedValue.customerEmail ? updatedValue.customerEmail : "",
      firstName: updatedValue.customerFirstName
        ? updatedValue.customerFirstName
        : "",
      lastName: updatedValue.customerLastName
        ? updatedValue.customerLastName
        : "",
      organizationName: updatedValue.customerName
        ? updatedValue.customerName
        : "",
      password: updatedValue.password ? updatedValue.password : "",
      phone: updatedValue.customerNumber ? updatedValue.customerNumber : "",
      venueSubscription: updatedValue.subscription ? updatedValue.subscription : "",
    };
    try {
      const request = await axios.put(
        requests.userHandler + `/${preGeneratedUserId}`,
        formData
      );
      setHandlePopUp(true);
      setSuccessMessage(CONSTANTS.VARIABLES.USER_EDITED_SUCCESS);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error.response);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/admin/");
  };
  const customerListHandler = () => {
    history("/admin/customerlist");
  };
  const newCustomer = () => {
    history("/admin/newcustomer");
  };

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const getUserIdAndPassword = async () => {
    try {
      const { data } = await axios.get(requests.getUserIdAndPassword);
      setUpdatedValue((preValue) => {
        return { ...preValue, password: data?.data.password };
      });
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      console.log(error)
    }
  };

  const handleReset = () => {
    getUserIdAndPassword();
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };

  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              {successMessage ? (
                <Dailog
                  handleCancel={handleCloseSuccess}
                  errorDailog={successMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                onSubmit={handleSubmit}
                sx={{ minHeight: "26rem", height: "auto" }}
              >
                <TextField
                  margin="normal"
                  required
                  id="customerId"
                  label="Customer ID"
                  name="customerId"
                  autoFocus
                  fullWidth
                  value={location?.state?.detail?.userId}
                  disabled={true}
                  style={classes.inputFieldBold}
                  size="small"
                  color="error"
                />
                {/* <i className="fas fa-copy"></i> */}
                <TextField
                  margin="normal"
                  required
                  id="password"
                  label="Password"
                  name="password"
                  autoFocus
                  fullWidth
                  size="small"
                  sx={{ mb: 3 }}
                  value={updatedValue.password}
                  style={classes.inputFieldBold}
                  color="error"
                  onChange={(e) => handleChange(e)}
                />
                <Dailog
                  title="Reset Password"
                  resetPassword={true}
                  handleReset={handleReset}
                />
                <TextField
                  margin="normal"
                  required
                  id="customerName"
                  label="Customer Name"
                  name="customerName"
                  value={updatedValue.customerName}
                  fullWidth
                  size="small"
                  color="error"
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="customerFirstName"
                  label="Customer Contact First Name"
                  name="customerFirstName"
                  fullWidth
                  size="small"
                  color="error"
                  value={updatedValue.customerFirstName}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="customerLastName"
                  label="Customer Contact Last Name"
                  name="customerLastName"
                  fullWidth
                  size="small"
                  color="error"
                  value={updatedValue.customerLastName}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="customerEmail"
                  label="Customer Contact Email"
                  name="customerEmail"
                  fullWidth
                  size="small"
                  color="error"
                  value={updatedValue.customerEmail}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="customerNumber"
                  label="Customer Contact Phone"
                  name="customerNumber"
                  fullWidth
                  size="small"
                  color="error"
                  value={updatedValue.customerNumber}
                  onChange={(e) => handleChange(e)}
                />
                <div className="timeZoneBox">
                  <i className="fas fa-caret-down select-icon"></i>
                  <select
                    name="subscription"
                    value={updatedValue.subscription}
                    id="subscription"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="1">Subscription - 1 Venue</option>
                    <option value="2">2 Venues</option>
                    <option value="3">3 Venues</option>
                  </select>
                </div>
                <Button
                  type="submit"
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default EditCustomer;
