import ActionTypes from "../constants/action-types";
const initialState = {
  isLoggedIn: false,
  authToken: ''
};
export const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_LOGGED_IN:
      return payload
    default:
      return state;
  }
}