export const weekdays = [
    {
        id: 1,
        weekday: 'MONDAY',
        streamStatus: false,
    },
    {
        id: 2,
        weekday: 'TUESDAY',
        streamStatus: false,
    },
    {
        id: 3,
        weekday: 'WEDNESDAY',
        streamStatus: false,
    },
    {
        id: 4,
        weekday: 'THURSDAY',
        streamStatus: false,
    },
    {
        id: 5,
        weekday: 'FRIDAY',
        streamStatus: false,
    },
    {
        id: 6,
        weekday: 'SATAURDAY',
        streamStatus: false,
    },
    {
        id: 7,
        weekday: 'SUNDAY',
        streamStatus: false,
    },
]