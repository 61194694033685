import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useState } from "react";
import { useNavigate as useHistory } from "react-router-dom";
import "../Signin.scss";
// import { makeStyles } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router";
import CONSTANTS from "../../constants";
import Header from "../Shared/Header";
import Dailog from '../Shared/Dailog'
import axios from "../Shared/axios";

import requests from "../Shared/requests";
let rtmpId;

const VenueDashboard = (props) => {
  const { classes } = props;
  const history = useHistory();
  const location = useLocation();
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState();
  const [deleteVenue, setDeleteVenue] = useState(false)
  const [playerSrc, setPlayerSrc] = useState("");
  const [venueData, setVenueData] = useState({
    incomingRTMP: location?.state?.detail?.venueRtmpUrl,
    venueLocation: location?.state?.detail?.location,
    timeZone: location?.state?.detail?.timeZone,
    userId: location?.state?.detail?.userId,
    venueId: location?.state?.detail?.venueId,
    venueName: location?.state?.detail?.venueName,
  });
  const heading = location?.state?.detail?.venueName.toUpperCase();
  useEffect(() => {
    let arr = location?.state?.detail?.venueRtmpUrl.split("/");
    if (arr && arr.length > 0) {
      rtmpId = arr[arr.length - 1];
      setPlayerSrc(requests.playerSRC + location?.state?.detail?.userId + requests.configURl + rtmpId)
    }
  }, [location])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueData((preValue) => {
      return { ...preValue, [name]: value };
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      incomingRtmpUrl: rtmpId ? rtmpId : null,
      location: data.get("venueLocation"),
      userId: location?.state?.detail?.userId,
      venueName: data.get("venueName"),
    };
    try {
      const request = await axios.put(
        requests.createVenue + "/" + `${venueData.venueId}`,
        formData
      );
      setHandlePopUp(true);
      setPopUpMessage(CONSTANTS.VARIABLES.VENUE_EDITED_SUCCESS);
      event.target.reset();
      setTimeout(() => {
        history(-1);
      }, 2000);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      console.log(error);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };
  const customerListHandler = () => {
    history("/admin/customerlist");
  };
  const newCustomer = () => {
    history("/newcustomer");
  };

  const handleEditVenue = () => {
    history({
      pathname: "/editVenue",
      state: { detail: location?.state?.detail },
    });
  };

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Edit",
      handle: handleEditVenue,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const handleCancel = () => {
    setTimeout(() => {
      setDeleteVenue(false);
    }, 2000);
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };

  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                onSubmit={handleSubmit}
                sx={{ mt: 1, minHeight: "26rem", height: "auto" }}
              >
                <TextField
                  margin="normal"
                  required
                  id="venueId"
                  label="Venue ID"
                  name="venueId"
                  fullWidth
                  value={venueData.venueId}
                  style={classes.inputFieldBold}
                  size="small"
                  color="error"
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueName"
                  label="Venue Name"
                  name="venueName"
                  fullWidth
                  size="small"
                  value={venueData.venueName}
                  style={classes.inputFieldBold}
                  color="error"
                  onChange={(e) => handleChange(e)}
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueLocation"
                  label="Venue Location"
                  name="venueLocation"
                  fullWidth
                  size="small"
                  color="error"
                  value={venueData.venueLocation}
                  onChange={(e) => handleChange(e)}
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueTimeZone"
                  label="Venue Time Zone"
                  name="venueTimeZone"
                  fullWidth
                  size="small"
                  color="error"
                  value={venueData.timeZone}
                  onChange={(e) => handleChange(e)}
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="incomingRTMP"
                  label="Incoming RTMP"
                  name="incomingRTMP"
                  fullWidth
                  size="small"
                  color="error"
                  value={venueData.incomingRTMP}
                  onChange={(e) => handleChange(e)}
                  disabled={true}
                />
                <div className="rtmp-preview-box">
                  <iframe
                    title="liveStream"
                    width="100%"
                    height="100%"
                    src={playerSrc}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default VenueDashboard;
