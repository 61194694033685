import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate as useHistory, useLocation } from "react-router-dom";
import CONSTANTS from "../../constants";
import Dailog from "../Shared/Dailog";
import Header from "../Shared/Header";
import Popup from "../Shared/PopUp";
import axios from "../Shared/axios";
import { getQueryString } from "../Shared/data";
import requests from "../Shared/requests";
import "../Signin.scss";
import CountdownTimer from "../Shared/CountownTimer";
let heading = "VENUE NAME";

const VenueDashboard = (props) => {
  const location = useLocation();
  const { classes } = props;
  const [goLive, setGoLive] = useState("");
  const [goliveToggle, setGoLiveToggle] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState();
  const [playerUrl, setPlayerUrl] = useState("");
  const [alreadyLive, setAlreadyLive] = useState(false);
  const [configuredData, setConfiguredData] = useState("");
  const [preventLive, setPreventLive] = useState(false);
  const [preventManualStop, setPreventManualStop] = useState(false);
  const [manualStreamLimit, setManualStreamLimit] = useState(null);
  const [manualLiveStarted, setManualLiveStarted] = useState(false);
  const [liveTypeManual, setLiveTypeManual] = useState(false);
  const prevTime = useRef(null);
  const mediaRef = useRef(null);
  const history = useHistory();
  const statusInterval = useRef(null);
  useEffect(() => {
    if (location?.state?.detail?.venueRtmpUrl) {
      let arr = location?.state?.detail?.venueRtmpUrl.split("/");
      let id = arr[arr.length - 1];
      setPlayerUrl(
        requests.playerSRC +
        location?.state?.detail?.userId +
        requests.configURl +
        id
      );
      heading = location?.state?.detail?.venueName.toUpperCase();
    }
  }, [location && location?.state?.detail?.venueRtmpUrl]);

  useEffect(() => {
    if (location?.state?.detail?.venueId) {
      getBroadcastDetail();
    }
    getLiveStatus();
    checkLiveStatusInterval()
    return () => clearInterval(statusInterval.current);
  }, [location?.state?.detail?.venueId]);

  useEffect(() => {
    goLiveTogg();
  }, [goliveToggle]);

  const getLiveStatus = async () => {
    try {
      const res = await axios.get(requests.getLiveStatus, { params: { venueId: location?.state?.detail?.venueId } })
      handleLiveStatus(res.data.data)
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      console.log(error.response.data)
    }
  }

  function handleLiveStatus(data) {
    if (data.manualLiveStream) {
      setLiveTypeManual(true);
    } else if (data.broadcastStatus && !data.manualLiveStream) {
      prevTime.current = null
      setLiveTypeManual(false)
      setManualLiveStarted(false);
      setGoLiveToggle(false);
      setAlreadyLive(true);
    } else if (!data.broadcastStatus) {
      prevTime.current = null
      setLiveTypeManual(false);
      setManualLiveStarted(false);
      setGoLiveToggle(false);
      setAlreadyLive(false);
    }
  }


  function checkLiveStatusInterval() {
    if (statusInterval.current) clearInterval(statusInterval.current);
    statusInterval.current = setInterval(() => {
      getLiveStatus();
    }, 30000)
  }


  const goLiveTogg = async () => {
    if (goliveToggle) {
      try {
        const data = {
          venueId: location?.state?.detail?.venueId,
        };
        const request = await axios.post(
          requests.broadcastGolive + "?" + getQueryString(data)
        );
        setLiveTypeManual(true)
        setManualLiveStarted(true)
      } catch (error) {
        console.log(error);
        setLiveTypeManual(false)
        setHandlePopUp(true);
        if (error.response.status === 403) {
          handleLogout();
        }
        if (error.response.data.message) {
          setPopUpMessage(error.response.data.message);
        } else {
          setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
        }
        setGoLiveToggle(false);
      }
    }
  };

  useEffect(() => {
    if (preventLive) {
      setTimeout(() => {
        setPreventLive(false);
        setPreventManualStop(false);
      }, 3000);
    }
  }, [preventLive]);

  const getBroadcastDetail = async () => {
    try {
      const data = {
        venueId: location?.state?.detail?.venueId,
      };
      const request = await axios.get(
        requests.venueBroadccastDetail + "?" + getQueryString(data)
      );
      setManualStreamLimit(request?.data?.data?.manualStreamLimit)
      prevTime.current = request?.data?.data?.liveStartTime;
      setConfiguredData(request.data.data);
      if (request.data.data.live) {
        setAlreadyLive(true);
      } else {
        setManualLiveStarted(false);
        setGoLiveToggle(false);
        setAlreadyLive(false);
        setPreventLive(true);
      }
      if (!request.data.data.channels.some((c) => c.channelEnable)) {
        setIsConfigured(true);
      } else {
        setIsConfigured(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      console.log(error);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };

  const handleCancel = () => {
    setPreventManualStop(false);
    setGoLive(false);
  };

  const handleConfig = () => {
    history("/broadcastChannel", {
      state: {
        detail: location?.state?.detail,
        configuredData: configuredData,
      },
    });
  };

  const menuList = [
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];

  const handleGolive = () => {
    if (isConfigured) {
      setGoLive(true);
    } else {
      setGoLiveToggle(true);
      setGoLive(false);
    }
  };
  const handleBroadcastChannel = () => {
    history("/broadcastChannel", {
      state: {
        detail: location?.state?.detail,
        configuredData: configuredData,
      },
    });
  };
  const handleStop = async () => {
    if (alreadyLive && !goliveToggle) {
      liveTypeManual
        ? manualStopLive()
        : setPreventManualStop(true);
    } else {
      manualStopLive();
    }
  };

  async function manualStopLive() {
    try {
      const data = {
        venueId: location?.state?.detail?.venueId,
      };
      const request = await axios.put(
        requests.broadcastStopLive + "?" + getQueryString(data)
      );
      setLiveTypeManual(false);
      setManualLiveStarted(false);
      setGoLiveToggle(false);
      setAlreadyLive(false);
      setPreventLive(true);
      prevTime.current = null
    } catch (error) {
      if (error?.response?.status === 403) {
        handleLogout();
      } else {
        handlePopUp(true)
        setPopUpMessage(error.message)
        getBroadcastDetail();
      }
      console.log(error);
    }
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };
  const handleBroadcastList = () => {
    if (isConfigured) {
      setGoLive(true);
      return;
    }
    history("/broadcasts", {
      state: { detail: location?.state?.detail },
    });
  };

  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header
                menuList={menuList}
                heading={heading}
                venueHeading={true}
              />
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                sx={{ mt: 1, mb: 3 }}
              >
                {goLive ? (
                  <Dailog
                    handleCancel={handleCancel}
                    goLive={goLive}
                    handleConfig={handleConfig}
                  />
                ) : (
                  " "
                )}
                {preventManualStop ? (
                  <Dailog
                    handleCancel={handleCancel}
                    preventManualStop={preventManualStop}
                    manualStopLive={manualStopLive}
                  />
                ) : (
                  " "
                )}
                {handlePopUp ? (
                  <Dailog
                    handleCancel={handleClosePopup}
                    errorDailog={popUpMessage}
                  />
                ) : (
                  " "
                )}
                <div className="customer-detail" style={classes.boxWidth}>
                  <Typography className="detail">
                    {location?.state?.detail?.venueId}
                  </Typography>
                  <Typography className="detail">
                    {location?.state?.detail?.venueName}
                  </Typography>
                </div>
                <Typography variant="h1" align="center">
                  Live Preview
                </Typography>
                <Typography
                  // variant="body2"
                  style={{ fontSize: 13, fontWeight: 500, marginTop: 10 }}
                  align="center"
                // className={classes.fontInfo}
                >
                  Can't see venue preview video? Click{" "}
                  <a
                    target="blank"
                    href="https://www.gamecast.info/support"
                    style={classes.underLine}
                  >
                    here
                  </a>{" "}
                  to trouble shoot.
                </Typography>
                <div className="rtmp-preview-box">
                  <iframe
                    ref={mediaRef}
                    id="the-iframe"
                    title="liveStream"
                    width="100%"
                    height="100%"
                    src={playerUrl}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="go-live-box" style={classes.boxWidth}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>Maximum Manual Live Stream (HH:MM)</Typography>
                  <Typography variant='body' align='center' sx={{ color: 'red', mb: 2, fontWeight: 500 }}>
                    {/* {
                      goliveToggle || alreadyLive ?
                        manualStreamLimit :
                        timer
                    } */}
                    <CountdownTimer
                      initialTime={manualStreamLimit}
                      live={goliveToggle || alreadyLive}
                      manualStop={manualStopLive}
                      prevTime={prevTime.current}
                      manualLiveStarted={manualLiveStarted}
                      liveTypeManual={liveTypeManual}
                    />
                  </Typography>
                  <div className="manual-heading">
                    <Typography
                      variant="h1"
                      align="center"
                      sx={{ mt: 0 }}
                      className="preview-heading"
                    >
                      Manual GoLive
                    </Typography>
                    {goliveToggle || alreadyLive ? (
                      <span className="live-dot"></span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {goliveToggle || alreadyLive ? (
                      <Button
                        className="golive-button-active"
                        variant="contained"
                        sx={{ m: 1 }}
                      >
                        Live Now
                      </Button>
                    ) : (
                      <Button
                        className="golive-button"
                        variant="contained"
                        sx={{ m: 1 }}
                        onClick={handleGolive}
                        disabled={preventLive}
                      >
                        Go Live
                      </Button>
                    )}
                    {goliveToggle || alreadyLive ? (
                      <Button
                        className="golive-button"
                        variant="contained"
                        sx={{ m: 1 }}
                        onClick={handleStop}
                      >
                        Stop
                      </Button>
                    ) : (
                      <Button
                        className="golive-button-active"
                        variant="contained"
                        sx={{ m: 1 }}
                      >
                        Stop
                      </Button>
                    )}
                  </div>
                  {goliveToggle || alreadyLive ? (
                    <Typography
                      // variant="body2"
                      align="center"
                      // className={`${classes.fontInfo} preview-heading live-heading`}
                      style={{ fontSize: 13, fontWeight: 500 }}
                    >
                      You are broadcasting
                    </Typography>
                  ) : (
                    <Typography
                      // variant="body2"
                      align="center"
                      style={{ fontSize: 13, fontWeight: 500 }}
                    // className={`${classes.fontInfo} preview-heading`}
                    >
                      You are currently offline
                    </Typography>
                  )}
                </div>
                <Typography
                  // variant="body2"
                  align="center"
                  // className={classes.fontInfo}
                  style={{ fontSize: 13, fontWeight: 500, marginTop: 10 }}
                >
                  Broadcast Channel has to be configured
                  <br />
                  prior to live streaming
                </Typography>
                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 1 }}
                  onClick={handleBroadcastChannel}
                >
                  Broadcast Channel
                </Button>
                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1 }}
                  onClick={handleBroadcastList}
                >
                  Broadcasts
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default VenueDashboard;
