import React, {useEffect, useState} from "react";
import "../Signin.scss";
import { useNavigate as useHistory } from "react-router-dom";
import requests from "../Shared/requests";
import axios from "../Shared/axios";

const Footer = () => {
  const [version, setVersion] = useState(""); 
  const history = useHistory();
  useEffect(()=>{
    versionCheck();
    return () =>{
     return false
    }
  },[])
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };
  const versionCheck =async()=>{
    try{
      const { data }= await axios.get(requests.version)
      setVersion((data?.data)?data?.data:"1.01")
    }catch(error){
      if (error.response.status === 403) {
        handleLogout();
      }
      console.log(error);
    }
  }
  return (
    <footer>
      {version && 
      <h4>Vs {version}</h4>
      }
    </footer>
  );
};

export default Footer;
