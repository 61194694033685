import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactPaginate from "react-paginate";
import { Container } from "@mui/material";
import { useNavigate as useHistory } from "react-router-dom";
import Menu from "./Menu"
import { display } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 8,
    fontFamily: "Poppins",
    border: "1px solid black"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 10,
    fontFamily: "Poppins",
    cursor:"pointer",
    border:"1px solid",
    
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const PaginationTable = (props) => {
  const { classes, usersList, showDetails, tableEditList, setPageNumber, updatedList } = props;
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  let limit = 10;
  useEffect(() => {
    if (updatedList) {
      setpageCount(Math.ceil(updatedList.length / limit));
    }
  }, [updatedList]);

  const handlePageClick = async (data) => {
    setPageNumber(data.selected)
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ marginTop: "10px" }}
        className={"tableView"}
      >
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "40%" }}>User ID</StyledTableCell>
              <StyledTableCell sx={{ width: "60%" }} align="left">
                User Name
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersList?.map((row) => (
              <StyledTableRow
                key={row.userId}
                onClick={(e) => {
                  showDetails(e, row);
                }}
              >
                <StyledTableCell
                  sx={{ width: "40%", background:row.active != true ? "#a7a2a2":" " }}
                  component="th"
                  scope="row"
                >
                  {row.userId}
                </StyledTableCell>
                <StyledTableCell sx={{ width: "60%", position: "relative", background:row.active != true ? "#a7a2a2":" " }} align="left">{row.organizationName}
                <Menu customerOption={true} menuList={tableEditList} userData={row}/>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Container>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination-list"}
          pageClassName={"page-items"}
          pageLinkClassName={"page-links"}
          previousClassName={"page-items"}
          previousLinkClassName={"page-links"}
          nextClassName={"page-items"}
          nextLinkClassName={"page-links"}
          breakClassName={"page-items"}
          breakLinkClassName={"page-links"}
          activeClassName={"active-item"}
        />
      </Container>
    </>
  );
};

export default PaginationTable;
