import React, { useState, useEffect } from "react";
import "../Signin.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
// import searchIcon from "../../public/assets/search-solid.svg";
import Table from "../Shared/Table";
import Header from "../Shared/Header";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import CONSTANTS from "../../constants";
import Popup from "../Shared/PopUp";
import Dailog from "../Shared/Dailog";
const heading = "CUSTOMER LIST";
const regex = /^[0-9]*$/i;
const regex2 = /[A-Za-z]/i;
let pageList;
let venueId;

const Customerlist = (props) => {
  const [usersList, setUsersList] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [updatedList, setUpdatedList] = useState([]);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [deleteCustomer, setDeleteCustomer] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const { classes } = props;
  const history = useHistory();

  useEffect(() => {
    getUsersList();
    // return ()=>{
    //  return false
    // }
  }, []);

  useEffect(() => {
    let index = pageNumber * 10;
    let list = updatedList;
    if (pageNumber > 0) {
      if (list.length > 10) {
        pageList = list.slice(index, index + 10);
      }
    } else if (pageNumber === 0) {
      pageList = list.slice(index, 10);
    }
    setUsersList(pageList);
    // return ()=>{
    //   return false
    // }
  }, [pageNumber]);

  useEffect(() => {
    if (searchValue != "" && searchValue.length > 2) {
      let newList = [];
      if (regex.test(searchValue)) {
        newList = updatedList.filter((user) => {
          return user.userId.toString().includes(searchValue);
        });
      } else if (regex2.test(searchValue)) {
        newList = updatedList.filter((user) => {
          return user.organizationName
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        });
      }
      setUsersList(newList.length > 0 ? newList : []);
    } else {
      getUsersList();
      setUsersList(pageList);
    }
    // return ()=>{
    //   return false
    // }
  }, [searchValue]);

  const getUsersList = async () => {
    try {
      const { data } = await axios.get(requests.getAllUsers);
      setUpdatedList(data?.data);
      setUsersList(data?.data.slice(0, 10));
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      } else {
        setHandlePopUp(true);
        if (error.response.statusText) {
          setPopUpMessage(error.response.statusText);
        } else {
          setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
        }
        console.log(error.response);
      }
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/admin/");
  };

  const customerListHandler = () => {
    history("/admin/customerlist");
  };

  const newCustomer = () => {
    history("/admin/newcustomer");
  };

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];

  const showDetails = (e, data) => {
    history("/admin/customer", {
      state: { detail: data }
    });
  };
  const handleDelete = async (e, userData) => {
    e.stopPropagation();
    venueId = userData.userId;
    setDeleteCustomer(true);
  };
  const handleEditCustomer = (e, userData) => {
    e.stopPropagation();
    history("/admin/editCustomer", {
      state: { detail: userData }
    });
  };
  const handleDeleteCustomer = async () => {
    try {
      const data = await axios.delete(requests.userHandler + `/${venueId}`);
      getUsersList();
      setHandlePopUp(true);
      setPopUpMessage(CONSTANTS.VARIABLES.USER_DELETE_SUCCESS);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error);
    }
  };

  const tableEditList = [
    {
      menuItem: "Delete User",
      handle: handleDelete,
    },
    {
      menuItem: "Edit Customer",
      handle: handleEditCustomer,
    },
  ];
  const handleCancel = () => {
    setDeleteCustomer(false);
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };

  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              {deleteCustomer ? (
                <Dailog
                  handleCancel={handleCancel}
                  deleteCustomer={deleteCustomer}
                  handleDeleteCustomer={handleDeleteCustomer}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                sx={{ mt: 1 }}
              >
                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  onClick={newCustomer}
                >
                  New Customer
                </Button>
                <div className="searchBox">
                  <input
                    type="text"
                    name="search"
                    value={searchValue}
                    placeholder="Search By Name and ID"
                    onChange={(e) => handleChange(e)}
                  />
                  <i className="fas fa-search searchIcon"></i>
                </div>
                <Table
                  updatedList={updatedList}
                  classes={classes}
                  usersList={usersList}
                  showDetails={showDetails}
                  tableEditList={tableEditList}
                  setPageNumber={setPageNumber}
                />
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Customerlist;
