import { Box, Button, Container, CssBaseline, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../Shared/Header'
import TimePicker from 'rc-time-picker'
import Popup from "../Shared/PopUp"
import Dailog from "../Shared/Dailog"
import { useLocation, useNavigate } from 'react-router'
import moment from 'moment'
import axios from '../Shared/axios'
import requests from '../Shared/requests'
import MESSAGES from '../../constants/messages'
const ManualStream = ({ classes }) => {
    const history = useNavigate();
    const location = useLocation();
    const [handlePopUp, setHandlePopUp] = useState(false)
    const [popUpMessage, setPopUpMessage] = useState("")
    const [showError, setShowError] = useState(false);
    const [manualTime, setManualTime] = useState(null)
    const format = "HH:mm";

    const getMomentTime = (time) => {
        if (time) {
            const today = moment().format("YYYY-MM-DD");
            const momentInTime = moment(`${today} ${time}`);
            return momentInTime
        } else {
            return null
        }
    }

    useEffect(() => {
        getManualStreamLimit();
    }, [])

    async function getManualStreamLimit() {
        try {
            const userId = location.state.detail.userId
            const res = await axios.get(requests.manualStreamLimit, { params: { userId } })
            setManualTime(getMomentTime(res?.data?.data || "03:00"));
        } catch (error) {
            console.log(error.message)
        }
    }
    const validateTime = () => {
        const hour = moment(manualTime).format("HH");
        const minute = moment(manualTime).format("mm")
        if (!+hour && !+minute) {
            setShowError('Manual stream limit can\'t be 00:00');
        } else {
            handleSave();
        }
    }
    const handleSave = async () => {
        const data = {
            manualStreamLimit: {
                hour: moment(manualTime).format("HH"),
                minute: moment(manualTime).format("mm")
            },
            userId: location.state.detail.userId
        }
        try {
            const res = await axios.put(requests.manualStreamLimit, data)
            setHandlePopUp(true);
            setPopUpMessage(MESSAGES.MANUAL_STREAM_LIMIT_UPDATED)
        } catch (error) {
            setShowError(error.message);
            console.log(error)
        }
    }

    const handleLogout = () => {
        localStorage.removeItem("access_token");
        history("/admin/");
    };
    const customerListHandler = () => {
        history("/admin/customerlist");
    };
    const newCustomer = () => {
        history("/admin/newcustomer");
    };
    const handleEditCustomer = () => {
        history("/admin/editCustomer", {
            state: { detail: location?.state?.detail },
        });
    };

    const handleValueChange = (value) => {
        setManualTime(value);
    };
    const handleClosePopup = () => {
        setShowError(false);
    }

    const handleCloseSuccess = () => {
        setHandlePopUp(false)
        history(-1)
    }
    const menuList = [
        {
            menuItem: "New Customer",
            handle: newCustomer,
        },
        {
            menuItem: "Customer List",
            handle: customerListHandler,
        },
        {
            menuItem: "Edit Customer",
            handle: handleEditCustomer,
        },
        {
            menuItem: "Logout",
            handle: handleLogout,
        },
    ];


    return (
        <div>
            <div className="signin-body">
                <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
                    <CssBaseline />
                    <div className="main-box">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Header menuList={menuList} heading={'DAILY SCHEDULE'} />
                            {handlePopUp ? (
                                <Dailog
                                    handleCancel={handleCloseSuccess}
                                    errorDailog={popUpMessage}
                                />
                            ) : (
                                " "
                            )}
                            {showError ? (
                                <Dailog
                                    handleCancel={handleClosePopup}
                                    errorDailog={showError}
                                />
                            ) : (
                                " "
                            )}
                            <Box style={classes.boxWidth} >
                                <Typography variant='h6' align='center' sx={{ color: 'red', my: 3 }}>
                                    MANUAL STREAM LIMIT
                                </Typography>
                                <div className="time-box">
                                    <TimePicker
                                        value={manualTime}
                                        onChange={handleValueChange}
                                        showSecond={false}
                                        allowEmpty
                                        label="Manual Stream Limit"
                                        placeholder="hh:mm"
                                        className="timeInput"
                                        id="manualTimeInput"
                                        format={format}
                                        use12Hours={false}
                                        minuteStep={30}
                                        sx={{ mt: 2, mb: 2 }}
                                    />
                                    <label htmlFor="broadcastStartTime">
                                        Manual Stream Limit
                                    </label>
                                </div>
                                <Button
                                    className="sign-in-button"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2, mb: 4 }}
                                    onClick={validateTime}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default ManualStream