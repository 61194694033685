import React, { useEffect, useState, useRef } from "react";
import "../Signin.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logo from "../../assets/logo.jpg";
import { useNavigate as useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import * as CryptoJS from "crypto-js";
import { getUserDetails } from "../Shared/data";
import { useDispatch } from "react-redux";
import requests from "../Shared/requests";
import axios, { createApiInstance } from "../Shared/axios";
import jwt_decode from "jwt-decode";
import { loginActions } from "../../redux/actions";
import Footer from "../Shared/Footer";

let passPhrase = "passwordKey";
let keySize = 128 / 32;
let iterationCount = 1000;
let iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
let salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
let token;
let decoded;
let credentials;
let password;
const CustomerSingin = (props) => {
  const { classes } = props;
  const rememberId = useRef(null);
  const dispatch = useDispatch();
  const [signInError, setSignInError] = useState(false);
  const history = useHistory();
  const [signInValues, setSignInValues] = useState({
    userId: "",
    password: ""
  })
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("credential")) {
      const data = JSON.parse(localStorage.getItem("credential"))
      setSignInValues({
        userId: data.userId,
        password: data.password
      })
    }
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignInValues((preValue) => {
      return { ...preValue, [name]: value }
    })
  }

  const handleChecked = (e) => {
    setChecked(!checked)
  }

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      history({
        pathname: "/customerDashboard",
        state: { detail: decoded },
      });
    }
  }, []);

  const login = async () => {
    if (localStorage.getItem("access_token")) {
      history({
        pathname: "/customerDashboard",
        state: { detail: decoded },
      });
      const data = {
        isLoggedIn: true,
        token: localStorage.getItem("access_token"),
        ...getUserDetails(),
      };
    }
  };

  const fetchAccessTocken = async (credentials) => {
    try {
      // let ciphertext = encrypt(salt, iv, passPhrase, credentials.password);
      // let aesPassword = iv + "::" + salt + "::" + ciphertext;
      // password = btoa(aesPassword);
      const { data } = await axios.post(requests.login, {
        // params: {
        userId: credentials.userId,
        password: credentials.password,
        role: 'customer'
        // loginPortal: "CUSTOMER",
        // },
      });
      // const {data} = await createApiInstance().post('gamecast/user/login',{ 
      //   userId: 10001,
      //       password: 'string'
      //     })
      // const sessioTime = {
      //   tokenExpire: data.valid,
      //   timeStamp: new Date()
      // }
      localStorage.setItem("access_token", data.data.token);
      token = data.data.token;
      decoded = jwt_decode(token);
      dispatch(loginActions.setLoggedIn(decoded));
      login();
      // return true;
    } catch (error) {
      setSignInError("Invalid User ID or password. Please try again");
      console.log(error);
    }
  };

  const generateKey = (salt, passPhrase) => {
    let key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
      keySize: keySize,
      iterations: iterationCount,
    });
    return key;
  };
  const encrypt = (salt, iv, passPhrase, plainText) => {
    let key = generateKey(salt, passPhrase);
    let encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let reg = new RegExp("^[0-9]+$");
    if (data.get("userId") && data.get("password")) {
      if (reg.test(data.get("userId"))) {
        credentials = {
          userId: data.get("userId"),
          password: data.get("password"),
        };
        if (checked) {
          localStorage.setItem("credential", JSON.stringify(credentials))
        }
        fetchAccessTocken(credentials);
      } else {
        setSignInError("only numeric values are allowed");
      }
    } else {
      setSignInError("Invalid User ID or password. Please try again");
    }
  };
  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img className="gamecast-logo" src={logo} alt="Game Cast logo" />
              <Typography variant="h1">CUSTOMER PORTAL</Typography>
              <Typography
                variant="body2"
                color="error"
                className="alert-message"
              >
                {signInError ? signInError : " "}
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, padding: "0 6px" }}
                style={classes.boxWidth}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="userId"
                  label="User ID"
                  name="userId"
                  autoFocus
                  size="small"
                  style={classes.input}
                  color="error"
                  value={signInValues.userId}
                  onChange={handleChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  size="small"
                  style={classes.input}
                  color="error"
                  value={signInValues.password}
                  onChange={handleChange}
                />
                <div className="checkbox-container">
                  <input
                    ref={rememberId}
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_id"
                    value="value"
                    onClick={handleChecked}
                  />
                  <label htmlFor="checkbox_id" style={{ fontSize: 15, fontWeight: "400" }}>Remember my User ID</label>
                </div>
                <Button
                  className="sign-in-button"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2, pt: 1.5, pb: 1.5 }}
                >
                  Sign On
                </Button>
                <Typography
                  variant="body2"
                  align="center"
                  // className={`${classes.underLine} ${classes.pointer}`}
                  style={{ textDecoration: "underline", fontSize: 13 }}
                >
                  <a href="https://www.gamecast.info/support" target="blank" style={{ color: "black" }}>
                    Forgot User ID
                  </a>
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  // className={`${classes.underLine} ${classes.pointer}`}
                  style={{ textDecoration: "underline", fontSize: 13 }}
                >
                  <a href="https://www.gamecast.info/support" target="blank" style={{ color: "black" }}>
                    Forgot Password?
                  </a>
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  // className={classes.fontInfo}
                  style={{ fontSize: 13, fontWeight: "500", marginTop: 15 }}
                >
                  This portal is for existing customers.
                  <br />
                  <a href="https://www.gamecast.info/contact" target="blank" className={classes.underLine}>
                    Contact us
                  </a>{" "}
                  if you would like to sign up for
                  <br />
                  a GameCast Streaming Subscription.
                </Typography>
              </Box>
            </Box>
            <Footer />
          </div>
        </Container>

      </div>
    </>
  );
};

export default CustomerSingin;
