const getUserDetails = () => {
  const token = localStorage.getItem('access_token');
  const jwtData = token.split('.')[1];
  // const decodedJwtJsonData = window.atob(jwtData);
  return JSON.parse(window.atob(jwtData));
}

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const getQueryString = (params) => {
  let query = Object.keys(params).filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '');
  return query.map(key => key + '=' + params[key]).join('&');
};

const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
};
export { getUserDetails, parseJwt, getQueryString, getInitials}