import React from "react";
import logo from "../../assets/logo.jpg";
import Menu from "./Menu";
import { Typography } from "@mui/material";
import { useNavigate as useHistory } from "react-router-dom";

const Header = ({ menuList, heading, formErrors, location, venueHeading }) => {
  let origin = window.location.origin;
  let href = window.location.href;
  const history = useHistory();
  const backHandler = () => {
    if (heading == "BROADCASTS") {
      history("/venueDashboard", {
        state: { detail: location?.state?.detail },
      });
    } else if (venueHeading) {
      history("/");
    } else if (heading == "CUSTOMER LIST") {
      history("/admin");
    } else {
      history(-1);
    }
  };

  const handleHome = () => {
    if (href.includes(`${origin}/admin`)) {
      history("/admin/");
    } else {
      history("/");
    }
  };

  return (
    <>
      <Menu menuList={menuList} />
      <img
        className="gamecast-logo"
        onClick={handleHome}
        src={logo}
        alt="Game Cast logo"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "21rem",
          marginTop: 16,
          position: "relative"
        }}
      >
        <div style={{ flex: 1 }}>
          <Typography
            style={{ fontWeight: "bold", fontSize: 19, textAlign: "center" }}
          >
            {heading ? heading : "Heading"}
          </Typography>
        </div>
        <div style={{ position: "absolute", left: 0, top: -10 }}>
          {heading !== "DASHBOARD" && (
            <Typography
              style={{ fontWeight: "normal", fontSize: 35, cursor: "pointer" }}
              onClick={backHandler}
            >
              {`<`}
            </Typography>
          )}
        </div>
      </div>
      {formErrors ? (
        (heading == "NEW CUSTOMER" ||
          heading == "EDIT CUSTOMER" ||
          heading == "NEW VENUE" ||
          heading == "EDIT VENUE") &&
          Object.keys(formErrors)?.length > 0 ? (
          <Typography
            style={{
              fontSize: 10,
              color: "red",
              textAlign: "center",
              margin: 0,
              padding: 0,
            }}
          >
            {Object.keys(formErrors)?.length > 1
              ? "All fields are mandatory"
              : `${formErrors[Object.keys(formErrors)[0]]}`}
          </Typography>
        ) : (
          " "
        )
      ) : (
        " "
      )}
    </>
  );
};

export default Header;
