import React from "react";
import { Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import CustomerSingin from "./Components/Customer/CustomerSingin";
import CustomerDashboard from "./Components/Customer/CustomerDashboard";
import VenueDashboard from "./Components/Customer/VenueDashboard";
import BroadcastChannel from "./Components/Customer/BroadcastChannel";
import ErrorPage from "./Components/Shared/ErrorPage";
import Broadcasts from "./Components/Customer/Broadcasts";
import NewBroadcast from "./Components/Customer/NewBroadcast";
import EditBroadcast from "./Components/Customer/EditBroadcast";
import Guard from "./Components/Shared/Guard";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontFamily: "Poppins",
      fontSize: "1.2rem",
      fontWeight: 600,
      marginTop: "1.7rem",
    },
    // body1: {
    //   fontSize: "1.2rem",
    //   margin: "0 10px",
    // },
    // body2: {
    //   fontSize: "1rem",
    //   margin: "0 10px",
    // },
  },
  palette: {
    warning: {
      main: "#e21c21",
      light: "#e21c21",
      dark: "#e21c21",
      contrastText: "#fff",
    },
  },
});
const CustomerApp = () => {
  const classes = {
    tableView: {
      width: "21rem",
      height: "16rem",
      overflow: "auto",
    },
    input: {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    },
    inputFieldBold: {
      fontWeight: "600 !important",
    },
    boxWidth: {
      width: "21rem",
      overflow: "auto",
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    boxHeight: {
      minHeight: "26rem",
      height: "auto",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route exact path="/" Component={(props) => <CustomerSingin {...props} classes={classes} />} />
        <Route exact path="/customerDashboard" Component={(props) => <Guard><CustomerDashboard {...props} classes={classes} /></Guard>} />
        <Route exact path="/venueDashboard" Component={(props) => <Guard><VenueDashboard {...props} classes={classes} /></Guard>} />
        <Route exact path="/broadcastChannel" Component={(props) => <Guard><BroadcastChannel {...props} classes={classes} /></Guard>} />
        <Route exact path="/broadcasts" Component={(props) => <Guard><Broadcasts {...props} classes={classes} /></Guard>} />
        <Route exact path="/newBroadcast" Component={(props) => <Guard><NewBroadcast {...props} classes={classes} /></Guard>} />
        <Route exact path="/editBroadcast" Component={(props) => <Guard><EditBroadcast {...props} classes={classes} /></Guard>} />
        <Route Component={(props) => <ErrorPage {...props} classes={classes} />} />
      </Routes>
    </ThemeProvider>
  );
};

export default CustomerApp;
