import React, { useState, useEffect, useRef } from "react";
import "../Signin.scss";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
// import { makeStyles } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Header from "../Shared/Header";
import requests from "../Shared/requests";
import axios from "../Shared/axios";
import Dailog from "../Shared/Dailog";
import { useLocation } from "react-router";
import { getQueryString } from "../Shared/data";
import CONSTANTS from "../../constants";
const heading = "EDIT VENUE";
let rtmpId;
let renerateRtmpId;

const NewVenue = (props) => {
  const { classes } = props;
  const history = useHistory();
  const location = useLocation();
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [playerSrc, setPlayerSrc] = useState("");
  const [deleteVenue, setDeleteVenue] = useState(false)
  const [venueData, setVenueData] = useState({
    incomingRTMP: location?.state?.detail?.venueRtmpUrl,
    venueLocation: location?.state?.detail?.location,
    timeZone: location?.state?.detail?.timeZone,
    userId: location?.state?.detail?.userId,
    venueId: location?.state?.detail?.venueId,
    venueName: location?.state?.detail?.venueName,
  });
  const [reGenStatus, setRegenStatus] = useState(false);
  useEffect(() => {
    let arr = location?.state?.detail?.venueRtmpUrl.split("/");
    if (arr && arr.length > 0) {
      rtmpId = arr[arr.length - 1];
      setPlayerSrc(
        requests.playerSRC +
        location?.state?.detail?.userId +
        requests.configURl +
        rtmpId
      );
    }

  }, [location]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueData((preValue) => {
      return { ...preValue, [name]: value };
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      venueRtmpUrl: venueData.incomingRTMP,
      location: data.get("venueLocation"),
      userId: location?.state?.detail?.userId,
      timeZone: venueData.timeZone,
      venueName: data.get("venueName"),
    };
    try {
      const request = await axios.put(
        requests.createVenue + "/" + `${venueData.venueId}`,
        formData
      );
      setSuccessMessage(CONSTANTS.VARIABLES.VENUE_EDITED_SUCCESS);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error);
    }
  };

  const handleCloseSuccess = () => {
    setSuccessMessage("")
    if (!reGenStatus) {
      history(-1);
    }
    setRegenStatus(false)
  }

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };
  const customerListHandler = () => {
    history("/admin/customerlist");
  };
  const newCustomer = () => {
    history("/newcustomer");
  };

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const handleCancel = () => {
    setTimeout(() => {
      setDeleteVenue(false);
    }, 2000);
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };
  const regGenerateRtmp = async () => {
    try {
      let id = Math.floor(
        10000000000000000000 + Math.random() * 90000000000000000000
      );
      //let url = `rtmp://admin.gamecast.info/${location?.state?.detail?.userId}/${id}`;
      let url =
        requests.RtmpDomain + "/" + location?.state?.detail?.userId + "/" + id;
      setVenueData((preValue) => {
        return { ...preValue, incomingRTMP: url };
      });
      const reGendata = {
        streamId: id,
        userId: location?.state?.detail?.userId,
        venueId: location?.state?.detail?.venueId,
      };
      const data = axios.post(
        requests.createRtmpUrl,
        getQueryString(reGendata)
      );
      setPlayerSrc(
        requests.playerSRC +
        location?.state?.detail?.userId +
        requests.configURl +
        id
      );
      setRegenStatus(true);
      setSuccessMessage(CONSTANTS.VARIABLES.RTMP_REGENERATED);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error);
    }
  };
  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              {successMessage ? (
                <Dailog
                  handleCancel={handleCloseSuccess}
                  errorDailog={successMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                onSubmit={handleSubmit}
                sx={{ mt: 1, minHeight: "26rem", height: "auto" }}
              >
                <TextField
                  margin="normal"
                  required
                  id="venueId"
                  label="Venue ID"
                  name="venueId"
                  fullWidth
                  value={venueData.venueId}
                  style={classes.inputFieldBold}
                  size="small"
                  color="error"
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueName"
                  label="Venue Name"
                  name="venueName"
                  fullWidth
                  size="small"
                  value={venueData.venueName}
                  style={classes.inputFieldBold}
                  color="error"
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueLocation"
                  label="Venue Location"
                  name="venueLocation"
                  fullWidth
                  size="small"
                  color="error"
                  value={venueData.venueLocation}
                  onChange={(e) => handleChange(e)}
                />
                <div className="timeZoneBox">
                  <i className="fas fa-caret-down select-icon"></i>
                  <select
                    name="timeZone"
                    id="timeZone"
                    value={venueData.timeZone}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="EST">Eastern Standard Time</option>
                    <option value="CST">Central Standard Time</option>
                    <option value="MST">Mountain Standard Time</option>
                    <option value="PST">Pacific Standard Time</option>
                    <option value="HST">Hawaii Standard Time</option>
                  </select>
                </div>
                <TextField
                  margin="normal"
                  required
                  id="incomingRTMP"
                  label="Incoming RTMP"
                  name="incomingRTMP"
                  fullWidth
                  size="small"
                  color="error"
                  sx={{ mb: 3 }}
                  value={venueData.incomingRTMP}
                  onChange={(e) => handleChange(e)}
                />

                <Dailog
                  regGenerateRtmp={regGenerateRtmp}
                  title={"Re-generate RTMP Link"}
                  editVenue={true}
                  handleCancel={handleCancel}
                />
                <div className="rtmp-preview-box">
                  <iframe
                    title="liveStream"
                    width="100%"
                    height="100%"
                    src={playerSrc}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
                <Button
                  type="submit"
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewVenue;
