import React, { useState, useEffect } from "react";
import "../Signin.scss";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
// import { makeStyles } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Header from "../Shared/Header";
import requests from "../Shared/requests";
import axios from "../Shared/axios";
import { getQueryString } from "../Shared/data";
import { Prompt } from "react-router";
import { useLocation } from "react-router-dom";
import Dailog from "../Shared/Dailog";
import Popup from "../Shared/PopUp";
import CONSTANTS from "../../constants";

const heading = "NEW VENUE";
const errors = {};
let formEvent;
let formData;
let rtmpId;
let url;

const NewVenue = (props) => {
  const location = useLocation();
  const { classes } = props;
  const [preGeneratedVenueId, setPreGeneratedVenueId] = useState("");
  const [isSubmit, setIsSubmit] = useState("");
  const [venueValues, setVenueValues] = useState({
    venueName: "",
    venueLocation: "",
    timeZone: "EST",
    incomingRTMP: ""
  });
  const [newVenueCreated, setNewVenueCreated] = useState(false);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState();
  const history = useHistory();

  useEffect(() => {
    rtmpThing()
  }, []);

  const rtmpThing = async () => {
    rtmpId = Math.floor(10000000000000000000 + Math.random() * 90000000000000000000);
    url = requests.RtmpDomain + "/" + location?.state?.detail?.userId + "/" + rtmpId
    setVenueValues((preValue) => {
      return { ...preValue, "incomingRTMP": url };
    });
    try {
      const data = {
        userId: location?.state?.detail?.userId,
      };
      const request = await axios.get(
        requests.generateVenueId + "?" + getQueryString(data)
      );
      setPreGeneratedVenueId(request?.data?.data);

    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueValues((preValue) => {
      return { ...preValue, [name]: value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    formEvent = event;
    setIsSubmit(true);
    const data = new FormData(event.currentTarget);
    data.set("venueId", preGeneratedVenueId);
    formData = {
      venueRtmpUrl: venueValues.incomingRTMP,
      location: venueValues.venueLocation,
      timeZone: venueValues.timeZone,
      userId: location?.state?.detail?.userId,
      venueId: data.get("venueId"),
      venueName: venueValues.venueName,
    };
    validate(event, venueValues);
  };

  useEffect(() => {
    doAction()
  }, [Object.keys(errors) && isSubmit]);

  const doAction = async () => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      try {
        const request = await axios.post(requests.createVenue, formData);
        setNewVenueCreated(true);
        formEvent.target.reset();
      } catch (error) {
        if (error.response.status === 403) {
          handleLogout();
        }
        setHandlePopUp(true);
        if (error.response.data.message) {
          setPopUpMessage(error.response.data.message);
        } else {
          setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
        }
        console.log(error.response);
      }
    } else {
      setIsSubmit(false);
    }
  }

  const validate = (event, values) => {
    if (!values.venueName) {
      errors.venueName = "Venue Name is required";
    } else {
      delete errors.venueName;
    }
    if (!values.venueLocation) {
      errors.venueLocation = "Venue Location is required!";
    } else {
      delete errors.venueLocation;
    }
    return errors;
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };
  const customerListHandler = () => {
    history("/admin/customerlist");
  };
  const newCustomer = () => {
    history("/newcustomer");
  };

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];

  const handleClosePopup = () => {
    setHandlePopUp(false);
  };
  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header
                menuList={menuList}
                heading={heading}
                formErrors={errors}
              />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                onSubmit={handleSubmit}
                sx={{ mt: 1, height: "26rem" }}
              >
                <Dailog newVenueCreated={newVenueCreated} />
                <TextField
                  margin="normal"
                  required
                  id="venueId"
                  label="Venue ID"
                  name="venueId"
                  autoFocus
                  fullWidth
                  value={preGeneratedVenueId}
                  style={classes.inputFieldBold}
                  size="small"
                  color="error"
                  disabled={true}
                />
                {/* <i className="fas fa-copy"></i> */}
                <TextField
                  margin="normal"
                  required
                  id="venueName"
                  label="Venue Name"
                  name="venueName"
                  autoFocus
                  fullWidth
                  size="small"
                  style={classes.inputFieldBold}
                  value={venueValues.venueName}
                  onChange={handleChange}
                  color="error"
                />
                <TextField
                  margin="normal"
                  required
                  id="venueLocation"
                  label="Venue Location"
                  name="venueLocation"
                  fullWidth
                  size="small"
                  color="error"
                  value={venueValues.venueLocation}
                  onChange={handleChange}
                />
                <div className="timeZoneBox">
                  <i className="fas fa-caret-down select-icon"></i>
                  <select
                    name="timeZone"
                    id="timeZone"
                    value={venueValues.timeZone}
                    onChange={handleChange}
                  >
                    <option value="EST">
                      Eastern Standard Time
                    </option>
                    <option value="CST">
                      Central Standard Time
                    </option>
                    <option value="MST">
                      Mountain Standard Time
                    </option>
                    <option value="PST">
                      Pacific Standard Time
                    </option>
                    <option value="HST">
                      Hawaii Standard Time
                    </option>
                  </select>
                </div>
                <TextField
                  value={venueValues.incomingRTMP}
                  margin="normal"
                  required
                  id="incomingRTMP"
                  label="Incoming RTMP"
                  name="incomingRTMP"
                  fullWidth
                  size="small"
                  color="error"
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewVenue;
