import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";

const Guard = ({ children }) => {
  const history = useNavigate();
  const location = useLocation();

  const handleLogout = (type) => {
    localStorage.removeItem("access_token");
    type === 'admin' ?
      history("/admin/") :
      history("/");
  };


  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      const decoded = jwtDecode(localStorage.getItem('access_token'))
      if (!decoded.role.includes('ROLE_ADMIN') && location.pathname.includes('admin')) {
        handleLogout('admin');
      } else if (decoded.role.includes('ROLE_ADMIN') && !location.pathname.includes('admin')) {
        handleLogout('user');
      }
    } else {
      if (location.pathname.includes('admin')) {
        history("/admin/")
      } else {
        history("/")
      }
    }
  }, []);

  return (
    <>
      {children}
    </>
  );
};

export default Guard;
