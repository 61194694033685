import axios from 'axios';

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;

// const base_url = 'http://13.56.71.74:8080/';

// export const createApiInstance = () => {
//   const base = base_url
//   return axios.create({
//     baseURL: `${base}v1/`,
//   },
//     {
//       Authorization: null,
//       "Accept": 'application/json',
//       'Content-Type': 'application/json'
//     }
//   );
// }
// // v1/gamecast/user/login
const instance = axios.create({
  baseURL: '/'
});

instance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
  return config;
});

export default instance;