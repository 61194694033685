import React from "react";
import { Route, Routes } from "react-router-dom";
import VideoStreamsBox from "../src/Components/Poc/VideoStreamsBox";
import Dashboard from "./Components/Admin/Dashboard";
import SignIn from "./Components/Admin/Signin";
// import { createTheme, ThemeProvider } from "@mui/material";
import CustomerDetail from "./Components/Admin/CustomerDetail";
import Customerlist from "./Components/Admin/Customerlist";
import EditVenue from "./Components/Admin/EditVenue";
import NewVenue from "./Components/Admin/NewVenue";
import Newcustomer from "./Components/Admin/Newcustomer";
// import "@fortawesome/fontawesome-free/js/all.js";
import DaySetting from "./Components/Admin/DaySetting";
import EditCustomer from "./Components/Admin/EditCustomer";
import SystemSchedule from "./Components/Admin/SystemSchedule";
import VenueDashboard from "./Components/Admin/VenueDashboard";
import ErrorPage from "./Components/Shared/ErrorPage";
import Guard from "./Components/Shared/Guard";
import ManualStream from "./Components/Admin/ManualStream";
// import { makeStyles } from '@mui/styles'

const App = () => {
  const classes = {
    tableView: {
      width: "21rem",
      height: "16rem",
      overflow: "auto",
    },
    input: {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
      fontSize: "14px !important"
    },
    inputFieldBold: {
      fontWeight: "600 !important",
    },
    boxWidth: {
      width: "21rem",
      overflow: "auto",
      'marginRight': 'auto',
      'marginLeft': 'auto'
    },
    boxHeight: {
      minHeight: "26rem",
      height: "auto",
    },
  };
  return (
    // <ThemeProvider theme={theme}>
    <div>
      {/* <MemoryRouter initialEntries={["admin/admin"]}> */}
      <Routes>
        <Route path="admin/" Component={(props) => <SignIn {...props} classes={classes} />} />
        <Route exact path="admin/poc" Component={(props) => <Guard><VideoStreamsBox {...props} classes={classes} /></Guard>} />
        <Route path="admin/dashboard" Component={(props) => <Guard><Dashboard {...props} classes={classes} /></Guard>} />
        <Route path="admin/customerlist" Component={(props) => <Guard><Customerlist {...props} classes={classes} /></Guard>} />
        <Route path="admin/newcustomer" Component={(props) => <Guard><Newcustomer {...props} classes={classes} /></Guard>} />
        <Route path="admin/systemSchedule" Component={(props) => <Guard><SystemSchedule {...props} classes={classes} /></Guard>} />
        <Route path="admin/daySetting" Component={(props) => <Guard><DaySetting {...props} classes={classes} /></Guard>} />
        <Route path="admin/manualStream" Component={(props) => <Guard><ManualStream {...props} classes={classes} /></Guard>} />
        <Route path="admin/customer" Component={(props) => <Guard><CustomerDetail {...props} classes={classes} /></Guard>} />
        <Route path="admin/newVenue" Component={(props) => <Guard><NewVenue {...props} classes={classes} /></Guard>} />
        <Route path="admin/editVenue" Component={(props) => <Guard><EditVenue {...props} classes={classes} /></Guard>} />
        <Route path="admin/editCustomer" Component={(props) => <Guard><EditCustomer {...props} classes={classes} /></Guard>} />
        <Route path="admin/venueDashboard" Component={(props) => <Guard><VenueDashboard {...props} classes={classes} /></Guard>} />
        <Route Component={(props) => <ErrorPage {...props} classes={classes} />} />
      </Routes>
      {/* </MemoryRouter> */}
    </div>
  );
};

export default App;
