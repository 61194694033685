const MESSAGES = {
  TOAST_ERROR: "error",
  TOAST_ERROR_MESSAGE: "Error Message",
  TOAST_ERROR_TITLE: "Failed",
  UNABLE_TO_GET_USER: "Unable to get users.",
  UNABLE_TO_GET_CHAT_ROOMS: "Unable to get chatrooms.",
  TOAST_INFO: "info",
  TOAST_INFO_TITLE: "Update",
  TOAST_SUCCESS: "success",
  TOAST_NEW_MESSAGE: "New Message",
  TOAST_SUCCESS_TITLE: "Success",
  TOAST_WARNING: "warn",
  TOAST_WARING_TITLE: "Warning",
  CHAT_NAME_REQUIRED: "Chat Room Name is required.",
  SELECT_ATLEAST_MEMBER: "Please select atleast 1 member",
  ENABLE_USER_CHAT_ACCESS: "Please enable chat room for this user first.",
  ALREADY_ADMIN: "ALREADY_ADMIN",
  NO_CHATROOM_FOUND: "NO_CHATROOM_FOUND",
  ENTER_VALID_CHATROOM_NAME: "Please provide the valid chatroom name",
  ONLY_ADMIN_CAN_REMOVE_OTHER: "ONLY ADMIN CAN REMOVE OTHER",
  CHAT_CREATED: "Chat Room created successfully.",
  CHAT_ALREADY_EXIST: "Chat room name already exists.",
  CHAT_CREATE_FAILED: "Unable to create room.",
  UNABLE_TO_GET_MESSAGES: "Unable to get messages",
  UNABLE_TO_HOST_MEETING: "UNABLE_TO_HOST_MEETING",
  ENTER_TIME_AND_DATE: "ENTER_TIME_AND_DATE",
  UNABLE_TO_EDIT_MESSAGE: "Unable to Edit Message",
  MESSAGE_EDITED_SUCCESS: "Message Edit successfully",
  UNABLE_TO_REMOVE_MESSAGE: "Unable to Remove Message",
  TIME_LOCKED_SUCCESSFULLY: "TIME_LOCKED_SUCCESSFULLY",
  MESSAGE_REMOVE_SUCCESS: "Message Remove Successfully",
  UNABLE_TO_FORWARD_MESSAGE: "Message not sent",
  MESSAGE_ALREADY_SEND: "This message already sent to this chatroom",
  SOCKET_CONNECTION_FAILED:
    "You have been disconnected due to inactivity, reconnecting again.",
  MEMBERS_UPDATED: "Members are updated successfully.",
  FILTER_TYPE_IS_REQUIRED: "FILTER TYPE IS REQUIRED",
  ENTER_BOTH_DATES: "ENTER_BOTH_DATES",
  MEMBERS_NOT_UPDATE: "Unable to update members.",
  MESSAGE_REQUIRED: "Message is required.",
  SOCKET_ERROR: "Reconnecting...",
  FILES_UPLOADED: "File(s) uploaded successfully.",
  MANUAL_FILE_UPLOADED: "Manual File Uploaded Successfully.",
  FILES_NOT_UPLOADED: "Unable to upload file(s).",
  CANT_REMOVE_SELF_FROM_CHAT: "You can't remove yourself from chat room.",
  CANT_REMOVE_ONWER_FROM_CHAT: "Chat room owner can't be removed.",
  CALL_ACTIVE_MESSAGE:
    "Another call is active, please disconnect the call first.",
  CHAT_ROOM_NAME: "Enter the chat room name",
  CHAT_ROOM_NAME_CHANGED: "Chat room name changed successfully",
  PIN_ERROR_MESSAGE:
    "3 chatRoom only mark as favorite or it is already mark as favorite",
  PIN_SUCCESS_MESSAGE: "ChatRoom Pinned Successfully",
  UNPIN_SUCCESS_MESSAGE: "ChatRoom Unpinned Successfully",
  UNABLE_To_RENAME_CHATGROUP: "unable to rename chat group",
  FORM_SUBMIT: "Form is Submited Successfully",
  FORM_NOT_SUBMIT: "Something Went Wrong",
  UNABLE_TO_CREATE_PERSONAL_CHATROOM:
    "You have already inititate chat with this user",
  PLEASE_FILL_CAPTCHA: "Please fill the captcha",
  WAIT_FOR_RESPONSE: "Wait for response for few seconds",
  MESSAGE_MARKED_FAVORITE: "Message marked as favorite",
  MESSAGE_MARKED_UNFAVORITE: "Message marked as unfavorite",
  UNABLE_TO_GET_FAVORITE_MESSAGES: "unable to get favorite messages",
  USER_LEFT_THE_CALL: "User left the call",
  MEETING_NAME_SHOULD_BE_MANDATORY: "Title Name Should Be Required",
  ENTER_EXTERNAL_MEMBERS_EMAIL: "ENTER EXTERNAL MEMBERS EMAIL",
  INVITE_SEND_SUCCESSFULLY: "Invite Send Successfully",
  UNABLE_TO_SEND_INVITE: "UNABLE_TO_SEND_INVITE",
  MEETING_LINK_IS_ALREADY_GENERATED: "meeting link is already generated",
  ENTER_CORRECT_DATE_AND_TIME: "Enter Correct Date and Time",
  USER_JOIN_CALL: "User Join the call",
  USER_LEFT_THE_CALL: "User left the call",
  MEETING_NAME_SHOULD_BE_MANDATORY: "Title Name Should Be Required",
  ENTER_EXTERNAL_MEMBERS_EMAIL: "ENTER EXTERNAL MEMBERS EMAIL",
  INVITE_SEND_SUCCESSFULLY: "Invite Send Successfully",
  UNABLE_TO_SEND_INVITE: "UNABLE_TO_SEND_INVITE",
  MEETING_LINK_IS_ALREADY_GENERATED: "meeting link is already generated",
  PASSWORD_CHANGED_SUCCESSFULLY: "Password changed successfully",
  LINK_COPIED_SUCCESSFULLY: "Link Copied Successfully",
  SELECT_GUEST_FROM_OODLES: "Select Guest From Oodles",
  RECORDING_LINK_SEND_SUCCESSFULLY: "Recording Link Send Successfully",
  RECORDING_MESSAGE: "RECORDING_MESSAGE",
  UNABLE_TO_GET_FEEDBACK: "Unable to get FeedBack.",
  SYSTEM_SCHEDULE_SUCCESSFULLY: "System Scheduled Successfully",
  MANUAL_STREAM_LIMIT_UPDATED: "Manual Stream Limit Updated",
};

export default MESSAGES;
