import React, { useEffect, useState } from "react";
import "../Signin.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logo from "../../assets/logo.jpg";
import { useNavigate as useHistory } from "react-router-dom";
import { Typography } from "@mui/material";

const Error = () => {
  return (
    <>
    <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img className="gamecast-logo" src={logo} alt="Game Cast logo" />
              <div className="error-box">
              <Typography variant="h4" align="center" className="error-message">ERROR <br /> 404 </Typography>
              <Typography variant="h1" align="center" >Page could not be found</Typography>
              <Typography variant="h1" align="center" ><a href="/"> <i className="fas fa-arrow-left"></i> Go back to home</a></Typography>
              </div>
            </Box>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Error