import React from "react";
import "../Signin.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
import Header from "../Shared/Header";

const heading = "DASHBOARD"

const Dashboard = (props) => {
  const { classes } = props;
  const navigate = useHistory();
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/admin/");
  }
  const customerListHandler = () => {
    navigate("/admin/customerlist");
  }
  const newCustomer = () => {
    navigate("/admin/newcustomer");
  }

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer

    },
    {
      menuItem: "Customer List",
      handle: customerListHandler
    },
    {
      menuItem: "Logout",
      handle: handleLogout
    }
  ];

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            fullWidth
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header menuList={menuList} heading={heading} />
            <Box component="form" className={classes.boxWidth} noValidate fullWidth sx={{ mt: 1 }}>
              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={newCustomer}
              >
                New Customer
              </Button>
              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={customerListHandler}
              >
                Customer List
              </Button>
            </Box>
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
